import { Component,ViewChild,inject,TemplateRef} from '@angular/core';
import { OrdersService } from 'src/app/core/services/orders.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Validators } from '@angular/forms';
import { start } from 'repl';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountService } from 'src/app/core/services/count.service';
import * as moment from 'moment'; 
import { title } from 'process';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {
  pageSizeOptions: number[] = [10, 20, 50, 100]; 
  headerData: any;
  ogData:any;
  customers: any;
  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  pageIndex:number = 0;
  pageSize:number=4;
  page:any; 
  searchdata:any;  
  superuser:any;
  d2c_name:any;
  portal_name:any;
  portal_account_id:any;
  portal_id:any;
  order1:any;
  searchdata1 = true;
  searchdata2 = true;
  disableButtonCondition: boolean = false;
  createuserform: UntypedFormGroup;
  createuserform1: UntypedFormGroup;
  createuserform2: UntypedFormGroup;
  createuserform4: UntypedFormGroup;
  createuserform8: UntypedFormGroup;
  showOverlay =false  
  length=0
  fullData22 :any
  page_no:number=1;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false;
  config:any;
  headerid:any;
  length1:any;
  username:any;
  date_wise:boolean = false
  paginate: any = {
    length: 0,
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 1
    }
    value:any;
    searchchar:boolean = false
    selectedSearchType: 'orders' | 'products' = 'orders'; 
    searchTerm: string = '';
  pageEvent:any;
  startIndex:any
  displayedColumns: string[] | undefined;
  @ViewChild(MatPaginator, { static: true }) paginator:
    | MatPaginator
    | undefined;
  @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
  start_date = new FormControl();
end_date = new FormControl();
vendorForm = new FormControl('');
private modalService = inject(NgbModal);
  fulllength: any;
  @ViewChild('select') select: MatSelect;

  constructor(
    private _order:OrdersService,
    private authService:AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private countservice : CountService
    ){

  }
  ngOnInit(): void { 
    this.fetchData(this.paginate);
    this.superuser = localStorage.getItem('is_superuser')
   this.initializeDates() ;
   this.initform();  
   this.superuser = localStorage.getItem('is_superuser')
   if(this.superuser == 'true'){
     this.portal_name = localStorage.getItem('portal_name2')
     this.portal_id = localStorage.getItem('portal_id2')
     this.portal_account_id = localStorage.getItem('portal_account_id2')
     this.d2c_name = localStorage.getItem('d2c_name2')

   }
   else{
     this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
     this.portal_account_id = localStorage.getItem('portal_account_id')
     this.d2c_name = localStorage.getItem('d2c_name')
   }
  }
  openDropdown() {
    if (this.select) {
      this.select.open();
    }
  }
  initform(){
    this.createuserform = this.formBuilder.group({
      agent_remark: ['', [Validators.required]],
      voice_of_customer: ['', [Validators.required]],
      category: ['Select Category', [Validators.required]],
      // status: ['open', [Validators.required]],
      // disposition: ['pending', [Validators.required]],
      support_chat: ['', [Validators.required]],      
    });
    this.createuserform1 = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      financial_status: ['', [Validators.required]],
      email_id: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      title: ['', [Validators.required]],
      shipping_charge: ['', [Validators.required]],
      code: ['', [Validators.required]],
      line_items:this.formBuilder.array(([this.createItemFormGroup()]))
    });

    this.createuserform4 = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      email_id: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      awb: ['', [Validators.required]],
      location_latitude: ['', [Validators.required]],
      location_longitude: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });

    this.createuserform8 = this.formBuilder.group({
      order_id: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

    this.createuserform2 = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      payment_method: ['', [Validators.required]],
      email_id: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      payment_method_title: ['', [Validators.required]],
      shipping_charge: ['', [Validators.required]],
      set_paid: ['', [Validators.required]],
      line_items:this.formBuilder.array(([this.createItemFormGroup1()]))
    });
  }
  createItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      product: new FormControl('',[Validators.required]),
      quantity:new FormControl('',[Validators.required]),

    });
  }
  createItemFormGroup1(): FormGroup {
    return this.formBuilder.group({
      product: new FormControl('',[Validators.required]),
      quantity:new FormControl('',[Validators.required]),
    });
  }
  getItemFormArray(): FormArray {
    return this.createuserform1?.get('line_items') as FormArray;
  }
  getItemFormArray1(): FormArray {
    return this.createuserform2?.get('line_items') as FormArray;
  }
  addItem() {
    const itemArray = this.createuserform1.get('line_items') as FormArray;
    itemArray.push(this.createItemFormGroup());
  }
  addItem1() {
    const itemArray = this.createuserform2.get('line_items') as FormArray;
    itemArray.push(this.createItemFormGroup1());
  }
  removeItem(i: number): void {
    const itemsArray = this.createuserform1.get('line_items') as FormArray;
    itemsArray.removeAt(i);
  }
  removeItem1(i: number): void {
    const itemsArray = this.createuserform2.get('line_items') as FormArray;
    itemsArray.removeAt(i);
  }
  initializeDates() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 6);

    this.start_date.setValue(startDate);
    this.end_date.setValue(endDate);
  }

  fetchData(event:PageEvent) {
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    this.showOverlay=true 
this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

 let data = {
   "page":page_no,
   "size":this.pageSize,
   'portal_account_id':this.portal_account_id,

  
   
 };
//  console.log(data)
 this.authService.orders_view(data).subscribe((res: any) => {
  // console.log(res);
  let mes = JSON.parse(res);
  this.fulllength = mes.total_length
  // console.log(mes);
   if (mes.statusCode === 200) {
     this.showOverlay = false;
     this.length=mes.total_length
       this.fullData = mes.Data; 
       this.ogData =mes.Data
     this.headerData = mes.headers;
     this.getData(this.fullData); 
   } 
   else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(res.error, "");
      this.showOverlay = false; 
    }
    // this.showOverlay= false

   }
  
 }, 
 (error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});
}

fetchData1(){
  this.fetchData2(this.paginate)
}

fetchData2(event:PageEvent) {
  this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

//   this.showOverlay=true 
// this.paginate = event;
// this.length = +event.length;
// this.pageIndex = +event.pageIndex;
// this.pageSize = +event.pageSize;
// this.startIndex = +event.pageIndex * event.pageSize
// let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
const startDate = this.start_date.value.toISOString().split('T')[0]; // Extract date part
const endDate = this.end_date.value.toISOString().split('T')[0]; // Extract date part
const formattedDate1 = this.start_date!.value 
const formattedDate2 = this.end_date!.value
this.showOverlay=true 
const startdate1 = moment(this.start_date.value).format('YYYY-MM-DD')
const  enddate1 = moment(this.end_date.value).format('YYYY-MM-DD')
// console.log(statrdata1,'statrdata1')




let data = {
 "page":page_no,
 "size":this.pageSize,
 'portal_account_id':this.portal_account_id,
 'start_date':startdate1,
 'end_date':enddate1,


 
};
// console.log(data)
this.authService.orders_view(data).subscribe((res: any) => {
// console.log(res);
let mes = JSON.parse(res);
// console.log(mes);
 if (mes.statusCode === 200) {
   this.showOverlay = false;
  //  this.toastr.success(res.message, ''); 
  this.date_wise = true
  this.fulllength = mes.total_length
   this.length=mes.total_length
     this.fullData = mes.Data; 
     this.ogData =mes.Data
   this.headerData = mes.headers;
   this.getData(this.fullData); 
 } 
 else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
  }
  // this.showOverlay = false;
 }

}, 
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});
}

getData(data:any) {
  this.apiData = data;
  // console.log("this.apiData",this.apiData); 
  // console.log(this.apiData);
  // this.filteredPortal = this.apiData
  this.headerData.actions = "Action"; 
  delete this.headerData.tracking_url;
  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;
  // this.customers.paginator = this.paginator;
}
filteredPortal = [
  {status:'cancelled'},
  {status:'dispatched'},
  {status:'Delivered'},
  {status:'In Transit'},
  {status:'packed'},
  {status:'created'},
  {status:'on hold'},
]

placeholder_name:any
setplaceholder(data:any){
    this.placeholder_name=data
    this.isOptionSelected = true;
}

onStartDateSelected(event: MatDatepickerInputEvent<Date>) {
  const startDate = event.value;
  const maxEndDate = this.calculateMaxEndDate(startDate);
  this.end_date.setValue(null); // Reset the end date when start date changes
  this.end_date.enable(); // Enable the end date input
  this.end_date.setValidators([
    Validators.required,
    this.endDateValidator.bind(this, startDate, maxEndDate)
  ]);
}

private calculateMaxEndDate(startDate: Date): Date {
  const maxEndDate = new Date(startDate);
  maxEndDate.setDate(maxEndDate.getDate() + 30);
  return maxEndDate;
}

private endDateValidator(startDate: Date, maxEndDate: Date, control: FormControl): { [key: string]: any } | null {
  const endDate = control.value;
  if (endDate && endDate > maxEndDate) {
    return { maxEndDateExceeded: true };
  }
  return null;
}
selectedOption: string;
combinedValue:any;

applyFilter(filterValue: string) {
  this.searchTerm = filterValue; 
  if (this.selectedOption && this.searchTerm) {
    this.combinedValue = `${this.selectedOption}:${this.searchTerm}`;
    this.ordersearch(this.searchTerm,this.selectedOption);
  }
}
  // console.log(filterValue.value);
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // //  this. refreshIcon=true
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }

data:any;
onSearchChange() {
  // Trigger API call when the search term changes
}
ordersearch(value:any,key:any){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  // console.log(key);
  this.showOverlay=true 


  if (key === 'order_id') {
    const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
    this.data = {
      'order_id': cleanedOrderId,
      'portal_account_id': this.portal_account_id
    };
  } else {
    const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
    this.data = {
      'awb': cleanedOrderId,
      'portal_account_id': this.portal_account_id
    };
  }
  
  // console.log(this.data)
 
 this.authService.orders_search(this.data).subscribe((res: any) => {
  // console.log(res);
  let mes = JSON.parse(res);
  // console.log(mes);
  if (mes.statusCode === 200) {
    this.showOverlay = false;
    this.searchdata = mes.Data
    // console.log(this.searchdata,'this.serachdata')
  
   
  } 
  else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(res.error, "");
      this.showOverlay = false; 
    }
    
    // this.showOverlay=false

  }
 },
 (error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}
cancelSearch() {
  this.isCancelled = true;
  this.searchTerm = ''; // Clear the search term
  // You can add additional logic here based on your requirements
}
// applyFilter1(filterValue: any) {
//   // console.log(filterValue.value)
//   this.customers.filter = filterValue.value.trim().toLowerCase();
// //  this. refreshIcon=true
//   if (this.customers.paginator) {
//     this.customers.paginator.firstPage();
//   }
// }
applyFilter1(filterValue: any) {
  if(filterValue!=''){
    this.searchchar = true
    this.value = filterValue
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData3(this.paginate,filterValue)
  }
  else{
    this.searchchar = false
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData(this.paginate)
  }
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }
}
cancel_order(data:any){
  // console.log(data,'consoledaat')
  localStorage.setItem('order_data', JSON.stringify(data)); 
  this.order_status(data)
  // this.router.navigate(['/cancel'])



}
mes: any[] = []; 
searchdata3: any[] = [];
order2:any

order_status(order:any){
  this.showOverlay = true;
  this.order1= order.order_id
  this.order2 = order.dd_id
  // console.log(this.order1,'order1')

  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data ={
    'portal_id':this.portal_id,
    'portal_account_id':this.portal_account_id,
    'order_id': this.order1,
    // 'payment_method':this.fullData[0].payment_method,
    // 'reason': this.order_id
  }
  // console.log(data,'kjhgfdsasdfghjklkjhgfd')
  this.authService.order_status(data).subscribe((res: any) => {
    // console.log(res);
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      if(mes.message =='order is not available for return or cancellation'){
        this.toastr.error(mes.message, ''); 

      }
      else{
        this.toastr.success(mes.message, '');

      }
      this.searchdata1 = mes.is_return_avialable
      this.searchdata2 = mes.is_cancel_avialable
      if (this.searchdata1 || this.searchdata2) {
        this.router.navigate(['/page/retailripple/cancel']);
      } else {
      }
      this.disableButtonCondition = !this.searchdata1 && !this.searchdata2;

      // Iterate through searchdata and set disableButton for the specific row
      this.fullData.forEach(item => {
        // Set disableButton based on your conditions
        item.disableButton = item.dd_id === this.order2 && !this.searchdata1 && !this.searchdata2;
      });
      // console.log(this.fullData,'searchdata3')

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
    // console.log('error mesg',res)
  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});


}
openVerticallyCentered(content: TemplateRef<any>,row:any) {
  this.modalService.open(content, { centered: true,size: 's'});
  this.headerid = row.order_id
  // console.log(row,'row data')
  localStorage.setItem('portali',row.portal_id)
  localStorage.setItem('portal_idd',row.portal_account_id)
  localStorage.setItem('portal_iddd',row.order_id)
  localStorage.setItem('portal_idddd',row.awb)



}
ticket_create(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  this.username = localStorage.getItem('username')
  let data ={
    'portal_id': this.portal_id,
    'portal_account_id':this.portal_account_id,
    'order_id': localStorage.getItem('portal_iddd'),
    'awb': localStorage.getItem('portal_idddd'),
    'agent_remark':this.createuserform.value.agent_remark,
    'voice_of_customer':this.createuserform.value.voice_of_customer,
     'is_order_issue':'true',
     'category':this.createuserform.value.category,
     'disposition':'pending',
     'status':'open',
     'raised_by':localStorage.getItem('username'),
     'is_notification_read':'false'
  


  
  }
  data['support_chat'] = {
    [this.username] :this.createuserform.value.support_chat
  } 
  this.showOverlay=true
  this.authService.ticket_create(data).subscribe((res: any) => {
    // console.log(res,'ticket data');
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.toastr.success(mes.success, "");
      this.modalService.dismissAll('Close click');

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');

  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}
selectedPageSize: number = 10; // Default page size

onPageSizeChange(pageSize: number) {
  this.selectedPageSize = pageSize;
  console.log(' this.selectedPageSize', this.selectedPageSize)
}
not_allow_only_num_and_spl_char(value: any,inpu_feild_name:any){
  if (value) {
    if (/^[0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only numbers. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
    if (/^[^a-zA-Z0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only special characters. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
  }
  return null;
}
only_spl_and_num_not_accept(event: any,input_name:any,){
  const value = event.target.value;
    this.not_allow_only_num_and_spl_char(value,input_name); 
  
}


fetchData3(event:PageEvent,data1:any) {

 
  this.showOverlay = true
  this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

let data = {
 "page":page_no,
 "size":this.pageSize,
 'portal_account_id':this.portal_account_id,
 'search':data1

};

// console.log(data)
this.authService.orders_search2(data).subscribe((res: any) => {
// console.log(res);
let mes = JSON.parse(res);
// console.log(mes);
 if (mes.statusCode === 200) {
   this.showOverlay = false;
  //  this.toastr.success(res.message, ''); 
  this.date_wise = true
  this.fulllength = mes.total_length
   this.length=mes.total_length
     this.fullData = mes.Data; 
     this.ogData =mes.Data
   this.headerData = mes.headers;
   this.getData(this.fullData); 
 } 
 else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
  }
  // this.showOverlay = false;
 }

}, 
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});
}


fetchData4(event:PageEvent,data1:any) {

  if(data1 === 'packed'){
   data1 = 'dispatched'
  }
  else if(data1 === 'dispatched'){
   data1 = 'packed'
  }
 this.showOverlay = true
 this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
 this.superuser = localStorage.getItem('is_superuser')
 if(this.superuser == 'true'){
   this.portal_name = localStorage.getItem('portal_name2')
   this.portal_id = localStorage.getItem('portal_id2')
   this.portal_account_id = localStorage.getItem('portal_account_id2')
   this.d2c_name = localStorage.getItem('d2c_name2')

 }
 else{
   this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
   this.portal_account_id = localStorage.getItem('portal_account_id')
   this.d2c_name = localStorage.getItem('d2c_name')
 }

let data = {
"page":page_no,
"size":this.pageSize,
'portal_account_id':this.portal_account_id,
'search':data1

};

// console.log(data)
this.authService.orders_search2(data).subscribe((res: any) => {
// console.log(res);
let mes = JSON.parse(res);
// console.log(mes);
if (mes.statusCode === 200) {
  this.showOverlay = false;
 //  this.toastr.success(res.message, ''); 
 this.date_wise = true
 this.fulllength = mes.total_length
  this.length=mes.total_length
    this.fullData = mes.Data; 
    this.ogData =mes.Data
  this.headerData = mes.headers;
  this.getData(this.fullData); 
} 
else{
 if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
   // this.authService.logout()
   this.showOverlay=false
   this.router.navigate(['/auth/login'])
 }
 else{
   this.toastr.error(res.error, "");
   this.showOverlay = false; 
 }
 // this.showOverlay = false;
}

}, 
(error) => {
 this.showOverlay = false;
 if (error.status === 502) {
   this.toastr.error("502 Bad Gateway", "An error occurred");
 } else {
   this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
 }
});
}



fetchData11() {
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  this.showOverlay=true 
  console.log(this.d2c_name,'d2c_name')


let data = {

 'portal_account_id':parseInt(this.portal_account_id),
 'portal_id':parseInt(this.portal_id)


 
};
this.authService.product_get_order(data).subscribe((res: any) => {
let mes = JSON.parse(res);
 if (mes.statusCode === 200) {
   this.showOverlay = false;
   this.fullData22 = mes.body
   console.log(this.fullData22,'fullData22')


 } 
 else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
  }

 }

}, 
(error) => {
this.showOverlay = false;
if (error.status === 502) {
  this.toastr.error("502 Bad Gateway", "An error occurred");
} else {
  this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
}
});
}


openVerticallyCentered1(content: TemplateRef<any>) {
  this.fetchData11()
  this.modalService.open(content, { centered: true,size: 'lg'});
  if (this.d2c_name === 'woocommerce'){

  }
}

openVerticallyCentered4(content: TemplateRef<any>) {
  this.modalService.open(content, { centered: true,size: 'lg'});

}

create_order(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  this.showOverlay=true 


let data = this.createuserform1.value
this.value = this.createuserform1.value.line_items
console.log(this.createuserform1.value.line_items)
data['portal_id'] = this.portal_id
data['portal_account_id'] = this.portal_account_id
const productsToFind = this.value.map(item => item.product);
const productQuantitiesMap = this.value.reduce((acc, item) => {
  acc[item.product] = item.quantity;
  return acc;
}, {});
const matchedProducts = this.fullData22
    .filter(item => productsToFind.includes(item.product_name))
    .map(item => ({
        "portal_sku": item.shopify_portal_sku,
        "selling_price": item.shopify_upload_selling_price,
        "quantity": productQuantitiesMap[item.product_name] || 0,
        "variant_id": item.shopify_listing_id, // Assuming variant_id maps to shopify_listing_id
        "product_name": item.product_name
    }));console.log(matchedProducts);
data['line_items'] = matchedProducts

  this.authService.create_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 201) {
       this.showOverlay = false;
       this.toastr.success(res.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}

create_order1(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  this.showOverlay=true 


let data = this.createuserform2.value
this.value = this.createuserform2.value.line_items
console.log(this.createuserform2.value.line_items)
data['portal_id'] = this.portal_id
data['portal_account_id'] = this.portal_account_id
const productsToFind = this.value.map(item => item.product);
const productQuantitiesMap = this.value.reduce((acc, item) => {
  acc[item.product] = item.quantity;
  return acc;
}, {});
const matchedProducts = this.fullData22
    .filter(item => productsToFind.includes(item.product_name))
    .map(item => ({
        "quantity":  productQuantitiesMap[item.product_name] || 0,
        "product_id": item.shopify_listing_id, // Assuming variant_id maps to shopify_listing_id
    }));console.log(matchedProducts);
data['line_items'] = matchedProducts

console.log(data,'jhgfdsdfghjk')

  this.authService.create_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.toastr.success(res.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}
numericOnly(event): boolean {    
  let patt = /^[0-9 .]+$/;
  let result = patt.test(event.key);
  return result;
}




applyFilterVendor(filterValue: string) {
  if (filterValue.length==0) {
    this.filteredPortal=this.apiData
  }  
  else{
    this.apiData = this.apiData.filter((item: any) => {
      const actualValueWithoutSpaces = item.status.replace(/\s/g, '').toLocaleLowerCase();
      const filterValueWithoutSpaces = filterValue.replace(/\s/g, '').toLocaleLowerCase();        
      return actualValueWithoutSpaces.includes(filterValueWithoutSpaces);
    });      
  }
  console.log(this.apiData)
}
closedFilter() {
  console.log('log')
  this.searchchar = true
  this.value = this.vendorForm.value
  this.paginate = {
    length: 0,
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 1
    }
  this.fetchData4(this.paginate,this.vendorForm.value)

}
name11:any
phone11:any
email_id11:any;
awb11:any
address11:any
pincode11:any
status11:any

openVerticallyCentered2(content: TemplateRef<any>,row:any) {
  console.log(row,'dataa')
  this.name11 = row.name
  this.phone11 = row.phone
  this.email_id11 = row.email_id
  this.awb11 = row.awb
  this.address11 = row.address
  this.pincode11 = row.pincode
  this.status11 = row.status
  localStorage.setItem('edit_id',row.order_id)

  this.modalService.open(content, { centered: true,size: 'lg'});
}


update_order(){
  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = this.createuserform4.value
 data['order_id'] = localStorage.getItem('edit_id')
 data['portal_id'] = this.portal_id
 data['portal_account_id'] = this.portal_account_id
  this.authService.update_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.toastr.success(mes.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}
 partial_order(){
  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = this.createuserform8.value
  console.log(data,'data')
  this.authService.partial_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.fetchData(this.paginate)
       this.toastr.success(res.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}


}
