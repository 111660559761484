import { Component,ViewChild,inject,TemplateRef,ElementRef} from '@angular/core';
import { OrdersService } from 'src/app/core/services/orders.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Validators } from '@angular/forms';
import { start } from 'repl';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountService } from 'src/app/core/services/count.service';
import * as moment from 'moment'; 
import { title } from 'process';
import { MatSelect } from '@angular/material/select';
import * as XLSX from 'xlsx'
import { SharedService } from 'src/app/core/services/shared.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alternative-orders',
  templateUrl: './alternative-orders.component.html',
  styleUrls: ['./alternative-orders.component.scss']
})
export class AlternativeOrdersComponent {

   pageSizeOptions: number[] = [10, 20, 50, 100]; 
   private modalRef: NgbModalRef | null = null;
    headerData: any;
    ogData:any;
    customers: any;
    fullData: any;
    apiData: any = [];
    apiData1:any = [];
    pageIndex:number = 0;
    pageSize:number=4;
    page:any; 
    searchdata:any;  
    superuser:any;
    d2c_name:any;
    portal_name:any;
    portal_account_id:any;
    portal_id:any;
    order1:any;
    searchdata1 = true;
    searchdata2 = true;
    disableButtonCondition: boolean = false;
    createuserform: UntypedFormGroup;
    createuserform1: UntypedFormGroup;
    createuserform2: UntypedFormGroup;
    createuserform4: UntypedFormGroup;
    createuserform8: UntypedFormGroup;
    showOverlay =false  
    length=0
    fullData22 :any
    page_no:number=1;
    isCancelled: boolean = false;
    isOptionSelected: boolean = false;
    config:any;
    headerid:any;
    length1:any;
    username:any;
    date_wise:boolean = false
    paginate: any = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
      value:any;
      searchchar:boolean = false
      selectedSearchType: 'orders' | 'products' = 'orders'; 
      searchTerm: string = '';
    pageEvent:any;
    startIndex:any
    displayedColumns: string[] | undefined;
    @ViewChild(MatPaginator, { static: true }) paginator:
      | MatPaginator
      | undefined;
    @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
    start_date = new FormControl();
  end_date = new FormControl();
  vendorForm = new FormControl('');
  private modalService = inject(NgbModal);
    fulllength: any;
    @ViewChild('select') select: MatSelect;
    orderid = new FormControl("", [Validators.required]);

  
    constructor(
      private _order:OrdersService,
      private authService:AuthenticationService,
      private toastr: ToastrService,
      private router: Router,
      private formBuilder: UntypedFormBuilder,
      private countservice : CountService,
          private _shared:SharedService
      
      ){
  
    }
    ngOnInit(): void { 
      this.fetchData(this.paginate);


      
    this.createuserform8 = this.formBuilder.group({
      alternative_order_id: ['', [Validators.required]],
      alternative_payment_method: ['', [Validators.required]],
      quantity: [''],
      warehouse_id: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

    }





  
  fetchData(event:any) {

    this.showOverlay = true
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    // this.showOverlay=true 
this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

 let data = {
   "page":page_no,
   "size":this.pageSize,

  
   
 };
//  console.log(data)
 this.authService.get_alt_orders(data).subscribe((res: any) => {
  // console.log(res);
  let mes = JSON.parse(res);
  res = mes
  this.fulllength = mes.total
  // console.log(mes);
  res = mes
   if (mes.statusCode === 200) {
     this.showOverlay = false;
     this.length=mes.total
       this.fullData = mes.response; 
       this.ogData =mes.response
     this.headerData = mes.headers;
     this.getData(this.fullData); 
    //  this.selectAllChecked = false
   } 
   else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(res.error, "");
      this.showOverlay = false;
      this.fullData = []; 
    //  this.headerData = mes.column_headers;
     this.getData(this.fullData);  
    }
    // this.showOverlay= false

   }
  
 }, 
 (error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});
}

getData(data:any) {
  this.apiData = data;
  // console.log("this.apiData",this.apiData); 
  // console.log(this.apiData);
  // this.filteredPortal = this.apiData
  // this.headerData.actions = "Action"; 
  delete this.headerData.tracking_url;
  this.headerData = { select: "select", ...this.headerData }; 
  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;

  // this.customers.paginator = this.paginator;
}

applyFilter1(filterValue: any) {
  if(filterValue!=''){
    this.searchchar = true
    this.value = filterValue
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData3(this.paginate,filterValue)
  }
  else{
    this.searchchar = false
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData(this.paginate)
  }

}

applyFilter3(filterValue: string) {
  console.log("Filter Value: ", filterValue);

  // Trim whitespace from the filter value
  const cleanFilterValue = filterValue.trim().toLowerCase();

  if (cleanFilterValue !== '') {
    // Filter the apiresponse based on the clean filter value
    this.apiresponse = this.apiresponse.filter(item => {
      const buymoreSku = item.buymore_sku ? item.buymore_sku.toLowerCase() : '';
      const portalSku = item.portal_sku ? item.portal_sku.toLowerCase() : '';
      
      // Debug: Log each item being checked
      console.log(`Checking item: ${item.buymore_sku}, ${item.portal_sku}`);
      
      return buymoreSku.includes(cleanFilterValue) || portalSku.includes(cleanFilterValue);
    });

    console.log("Filtered Data: ", this.apiresponse);
  } else {
    // Reset to original data if filter is empty
    this.apiresponse = [...this.apiresponse11];
    console.log("Reset Data: ", this.apiresponse);
  }
}



fetchData3(event:PageEvent,data1:any) {
  this.showOverlay = true
  this.paginate = event;
this.length = +event.length;
this.pageIndex = +event.pageIndex;
this.pageSize = +event.pageSize;
this.startIndex = +event.pageIndex * event.pageSize
let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

let data = {
 "page":page_no,
 "size":this.pageSize,
 'search_key':data1

};
// console.log(data)
this.authService.get_alt_orders_search(data).subscribe((res: any) => {
// console.log(res);
let mes = JSON.parse(res);
res = mes
// console.log(mes);
 if (mes.statusCode === 200) {
   this.showOverlay = false;
  //  this.toastr.success(res.message, ''); 
  this.date_wise = true
  this.fulllength = mes.total
   this.length=mes.total
     this.fullData = mes.response; 
    //  this.ogData =mes.Data
   this.headerData = mes.headers;
   this.getData(this.fullData); 
 } 
 else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
    this.fullData = []; 
    //  this.ogData =mes.Data
   this.headerData = mes.column_headers;
   this.getData(this.fullData); 
  }
  // this.showOverlay = false;
 }

}, 
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});
}



openVerticallyCentered5(content: TemplateRef<any>) {
  this.orderid.reset()
  this.modalService.open(content, { centered: true,size: 'm'});

}

openVerticallyCentered6(content: TemplateRef<any>) {
  this.createuserform8.reset()
  this.modalService.open(content, { centered: true,size: 'lg'});

}

create_data:any

openVerticallyCentered8(content: TemplateRef<any>,data:any) {
  this.createuserform8.reset()
  this.create_data = data
  this.modalService.open(content, { centered: true,size: 'lg'});

}

apiresponse:any
alt_order_id:any
apiresponse11:any


get_clone_orders(content: TemplateRef<any>){
  this.showOverlay = true
  let data  = {
    'order_id' : this.orderid.value
  }
  this.authService.get_clone_orders(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.apiresponse = mes.response
       this.apiresponse11 = mes.response
       this.modalService.dismissAll()
       this.alt_order_id = `ALT_${this.orderid.value}`;
       this.modalService.open(content, { centered: true,size: 'xl'});


    

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}
multiSelect:any[]=[];
select1(event:any, data:any, i:any) {
  const isChecked1 = event.target.checked;
  if (isChecked1) {
    this.multiSelect.push(data);
  } 
  else {
    const index = this.multiSelect.findIndex(item => item.order_item_id === data.order_item_id);
    if (index !== -1) {
      this.multiSelect.splice(index, 1);
    }
  }

  console.log(this.multiSelect)
}

isSelected(kot_order_id: string): boolean {
  const isSelected = this.multiSelect.includes(kot_order_id);
  return isSelected;
}


selectAllChecked: boolean = false; 
selectedEntries:any[]=[]

selectedItems: Set<any> = new Set<any>();





toggleAllRows(event: Event): void {
  this.selectedEntries = []
  const isChecked = (event.target as HTMLInputElement).checked;
  this.selectAllChecked = isChecked; 
  this.apiresponse.forEach(row => {
    if (row) {
      row.selected = isChecked;
      if (isChecked) {
        this.selectedEntries.push(row);
        this.selectedItems.add(row);
      } else {
        const index = this.selectedEntries.findIndex(selectedEntry => selectedEntry.order_item_id == row.order_item_id);
        if (index !== -1) {
          this.selectedEntries.splice(index, 1);
        }
        this.selectedItems.delete(row);
      }
    }
  });
  console.log(this.selectedEntries,'this.selectedEntries')
}
order_ids:any

filterBasedStatus1(event, checked) {
  const { order_item_id } = event;
  if (checked.target.checked) {
      this.selectedEntries.push(event);
      this.selectedItems.add(event);
      this.customers.data.forEach(row => {
          if (row.order_item_id == event.order_item_id) {
              row.selected = true;
          }
      });
  } else {
      const index = this.selectedEntries.findIndex(selectedEntry => selectedEntry.order_item_id == order_item_id);
      if (index !== -1) {
          this.selectedEntries.splice(index, 1);
      }
      this.apiresponse.forEach(row => {
          if (row.order_item_id == event.order_item_id) {
              row.selected = false;
          }
      });
      this.selectedItems.delete(event);
      if (this.selectedEntries.length === 0) {
        // this.selected_method2('1','20')
      }
  }
  console.log(this.selectedEntries)

 
}


openVerticallyCentered(content: TemplateRef<any>,data:any) {
  localStorage.setItem('order_item_id_id',data.order_item_id)
  this.modalRef = this.modalService.open(content, { centered: true, size: 's' });
}


deleteItem(): void {
  const orderItemId = localStorage.getItem('order_item_id_id')
  this.apiresponse = this.apiresponse.filter(item => item.order_item_id !== orderItemId);
  if (this.modalRef) {
    this.modalRef.close();
    this.modalRef = null;
  }

}
searchText = ''

filterTable(): void {
  const search = this.searchText.toLowerCase();
  this.apiresponse = this.apiresponse.filter(item =>
    item.buymore_sku.toLowerCase().includes(search) ||
    item.portal_sku.toLowerCase().includes(search)
  );
}
apiresponse1:any

applyFilter2(filterValue: any) {
  if(filterValue!=''){
    const search = filterValue.toLowerCase();
    this.apiresponse1 = this.apiresponse.filter(item =>
      item.buymore_sku.toLowerCase().includes(search) ||
      item.portal_sku.toLowerCase().includes(search)
    );

    
    
  }
  else{
    this.apiresponse1 = this.apiresponse
  
  }
 
}



update_clone_orders(){
  this.showOverlay = true
  let data  = {
    'alternate_order_id' : this.createuserform8.value.alternative_order_id,
    'alternate_payment_method':this.createuserform8.value.alternative_payment_method,
    'original_order_id': this.orderid.value,
    'payment_method':this.apiresponse[0].payment_method,
    'reason':this.createuserform8.value.reason,
    'status':'pending',
    'original_warehouse_id':this.apiresponse[0].warehouse_id,
    'alternate_warehouse_id': this.createuserform8.value.warehouse_id
  }
  data['product_data'] = {};
  this.apiresponse.forEach(item => {
    if (item.buymore_sku) {
      data['product_data'][item.buymore_sku] = item.qty;
    }
  });  
  console.log(data,'data')
  this.authService.create_clone_orders(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.apiresponse = mes.response
       this.modalService.dismissAll()
      //  this.modalService.open(content, { centered: true,size: 'xl'});


    

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}


update_clone_orders1(){
  this.showOverlay = true
  let data  = {
    'alternate_order_id' : this.createuserform8.value.alternative_order_id,
    'alternate_payment_method':this.createuserform8.value.alternative_payment_method,
    'original_order_id': this.orderid.value,
    'payment_method':this.create_data.payment_method,
    'reason':this.createuserform8.value.reason,
    'status':'pending',
    'original_warehouse_id':this.create_data.warehouse_id,
    'alternate_warehouse_id': this.createuserform8.value.warehouse_id
  }
  data['product_data'] = {};
  // this.apiresponse.forEach(item => {
  //   if (item.buymore_sku) {
  //     data['product_data'][this.create_data.buymore_sku ] = this.createuserform8.value.quantity;
  //   }
  // });  
  data['product_data'][this.create_data.buymore_sku] = this.createuserform8.value.quantity;

  console.log(data,'data')
  this.authService.create_clone_orders(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.modalService.dismissAll()
       this.toastr.success(res.response, ''); 

      //  this.modalService.open(content, { centered: true,size: 'xl'});


    

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}

editItem(item: any) {
  item.isEditing = true;
}

saveChanges(item: any) {
  console.log('Saved quantity:', item.qty);
  item.isEditing = false;
}

cancelEdit(item: any) {
  item.isEditing = false;
}

validateQuantity(item: any) {
  if (item.qty <= 0) {
    item.qty = 1; // Reset the input if invalid
  }
}



}
