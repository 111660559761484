<div class="container-fluid">
  <!-- <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <div class=" mb-3 d-flex justify-content-between" >

    <h1 class="page col-md-6 mb-4" *ngIf="admin1 === false">Dashboard</h1>
    <h1 class="page col-md-6 mb-4" *ngIf="admin1 === true">Admin Dashboard</h1>
    <div class="d-flex">
      <!-- <button class="mb-4 p-2 bg-primary me-3" style="color: white; border: 0px;border-radius: 5px;"  *ngIf="superuser=='true'" (click)="route111()">Generate Post P&L</button>
      <button class="mb-4 p-2 bg-primary me-3" style="color: white; border: 0px;border-radius: 5px;"  *ngIf="superuser=='true'" (click)="route11()">Weight Dispute View</button> -->
      <button class="mb-4 p-2 bg-primary" style="color: white; border: 0px;border-radius: 5px;" (click)="admin()" *ngIf="admin1 === false && this.superuser=='true'">Admin Dashboard</button>
      <button class="mb-4 p-2 bg-primary" style="color: white; border: 0px;border-radius: 5px;" (click)="admin2()" *ngIf="admin1 === true && this.superuser=='true'">Dashboard</button>
      
    </div>


    <!-- <div class="">
        <mat-form-field appearance="outline">
            <mat-label>Select</mat-label>
            <mat-select [(ngModel)]="selectedOption"> 
                <mat-option (click)="setplaceholder('Search')">Clear</mat-option> 
                <mat-option value="order_id" (click)="setplaceholder('Search For Order id')">Order Id</mat-option>
                <mat-option value="awb" (click)="setplaceholder('Search For Awb')">Awb</mat-option> 
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>{{placeholder_name}}</mat-label>
            <input matInput placeholder="" value="" [(ngModel)]="searchTerm"  (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
        <div *ngIf="!isCancelled" style="margin-top: -20px;">              
            <div *ngFor="let item of searchdata" class="card d-flex justify-content-between  card11 p-2" routerLink="/details">
              <div class="card-body d-flex justify-content-between">
                <div>
                  <p>Order ID: {{ item.order_id }}</p>
                  <p><strong>AWb:</strong> {{ item.awb }}</p>
                </div>
                <div>
                  <button class="btn-danger  button" (click)="cancelSearch()">Cancel</button>             
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button class="btn-danger  button" (click)="cancelSearch()">Cancel</button>
              </div>
            </div>
          
        </div>
          
          
    </div> -->

  </div>



  <div class="row" *ngIf="admin1 === false">
    <div class="col-xl-8 justify-content-between">
      <div class="row justify-content-between col-md-12 ms-1 laptop">
        <div class="card mb-4 p-3 col-md-4 card222">
          <p class="d-flex justify-content-end" style="font-size: 10px; color: green;">Last 30 Days</p>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">COD
              Orders</p>
            <p class="d-flex justify-content-end">{{this.cod_count}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">COD
              Orders SP</p>
            <p style="align-items: end!important;">{{ '₹' + (this.cod_sp | number:'1.0-0') }}
            </p>
          </div>
        </div>
        <div class="card mb-4 p-3 col-md-4 card222">
          <p class="d-flex justify-content-end" style="font-size: 10px; color: green;">Last 30 Days</p>
          <div class="d-flex justify-content-between">

            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Prepaid
              Orders</p>
            <p class="d-flex justify-content-end">{{this.prepaid_orders_count}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Prepaid
              Orders SP</p>
            <p style="align-items: end!important;">{{ '₹' + (this.prepaid_orders_sp | number:'1.0-0')}}</p>
          </div>
        </div>
        <div class="card mb-4 p-3 col-md-4 card222">
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 12px;">Customers View</p>
            <p style="align-items: end!important; font-size:11px;"><span style="cursor: pointer;" routerLink="/page/retailripple/repeated"><i class="fas fa-eye ms-2"></i>
            </span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Platform
              Name</p>
            <p style="align-items: end!important;">{{platform_name}}</p>
          </div>

          <div class="d-flex justify-content-between">

            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Customers
              Count</p>
            <p class="d-flex justify-content-end">{{this.count1}}</p>
          </div>

        </div>
      </div>
      <div class="row justify-content-center col-md-12 p-2 mobile">
        <div class="card mb-4 p-3 col-md-4" style=" height: 125px; width:auto;box-shadow: 1px 1px 1px 1px grey">
          <p class="d-flex justify-content-end" style="font-size: 10px; color: green;">Last 30 Days</p>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">COD
              Orders</p>
            <p class="d-flex justify-content-end">{{this.cod_count}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">COD
              Orders SP</p>
            <p style="align-items: end!important;">{{this.cod_sp | currency:'INR'}}</p>
          </div>
        </div>
        <div class="card mb-4 p-3 col-md-4" style="height: 125px; width: auto; box-shadow: 1px 1px 1px 1px grey">
          <p class="d-flex justify-content-end" style="font-size: 10px; color: green;">Last 30 Days</p>
          <div class="d-flex justify-content-between">

            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Prepaid
              Orders</p>
            <p class="d-flex justify-content-end">{{this.prepaid_orders_count}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Prepaid
              Orders SP</p>
            <p style="align-items: end!important;">{{this.prepaid_orders_sp | currency:'INR'}}</p>
          </div>
        </div>
        <div class="card mb-4 p-3 col-md-4" style="height: 125px; width: auto; box-shadow: 1px 1px 1px 1px grey">
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Customers View</p>
            <p style="align-items: end!important;"><span style="cursor: pointer;" routerLink="/page/retailripple/repeated"><i class="fas fa-eye ms-2"></i>
            </span></p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Platform
              Name</p>
            <p style="align-items: end!important;">{{platform_name}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Customers
              Count</p>
            <p class="d-flex justify-content-end">{{this.count1}}</p>
          </div>
        </div>
      </div>
      <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="card-body pb-1">
          <div class="float-end d-none d-md-inline-block">
            <!-- <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Weekly');"
                [class.active]="selectedInterval === 'Weekly'">Weekly</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Monthly');"
                [class.active]="selectedInterval === 'Monthly'">Monthly</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Yearly');"
                [class.active]="selectedInterval === 'Yearly'">Yearly</button>
            </div> -->
          </div>
          <div class="d-flex justify-content-between">
            <h4 class="card-title mb-4">Sales Analysis</h4>
            <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Weekly');"
                [class.active]="selectedInterval === 'Weekly'">Weekly</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Monthly');"
                [class.active]="selectedInterval === 'Monthly'">Monthly</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetch_data('Yearly');"
                [class.active]="selectedInterval === 'Yearly'">Yearly</button>
            </div>
          </div>
          <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart"
            [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid"
            [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
            [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors"
            [fill]="basicColumChart.fill">
          </apx-chart>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card" style="box-shadow: 1px 1px 1px 1px grey" >
          <div class="card-body">
            <h4 class="card-title mb-4">Order Analysis</h4>
            <apx-chart class="apex-charts" dir="ltr" [series]="splineAreaChart.series" [chart]="splineAreaChart.chart"
              [dataLabels]="splineAreaChart.dataLabels" [stroke]="splineAreaChart.stroke"
              [colors]="splineAreaChart.colors" [xaxis]="splineAreaChart.xaxis" [grid]="splineAreaChart.grid"
              [tooltip]="splineAreaChart.tooltip"></apx-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card  p-3" style="box-shadow: 1px 1px 1px 1px grey;height: 125px;">
        <!-- <p class="d-flex justify-content-center" style="font-size: 15px; font-weight: 700;">Orders</p> -->
        <div class="d-flex justify-content-between mt-3">
          <p class="d-flex justify-content-start mt-1" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Last 30
            Days Orders</p>
          <p class="d-flex justify-content-end mt-1">{{this.last_thirty_days_orders_count }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="d-flex justify-content-start" style="color: #0c98a6; font-weight: 700; font-size: 13px;">Today
            Orders</p>
          <p style="align-items: end!important;">{{this.todays_orders_count}}</p>
        </div>
      </div>
      <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="d-flex justify-content-between p-3 mt-4">
          <h5>Top Selling Products</h5>
          <p style="font-size: 10px;">Last 30 Days</p>
        </div>
        <div class="card-body">
          <div class="card d-flex  p-2 mb-5 pt-4" *ngFor="let status of toporder; let i = index"
            style="box-shadow: 1px 1px 1px 1px grey;height: 210px;">
            <div class="d-flex">
              <div>
                <span *ngIf="i === 0" class="gold ms-3 mt-4" style="font-size: 10px; display: flex;">
                  <img src="assets/images/gold.png" width="20px">
                  <img src="assets/images/gold.png" width="20px">
                  <img src="assets/images/gold.png" width="20px">
                </span>
                <span *ngIf="i === 1" class="silver ms-4 mt-4" style="font-size: 10px;"><img src="assets/images/silver.png" width="20px"><img src="assets/images/silver.png" width="20px"></span>
                <p *ngIf="i === 2" class="bronze ms-4 mt-4" style="font-size: 10px;"><img src="assets/images/bronze.png" width="20px" class="ms-1"></p>
                <img src="{{status.image_path}}" width="80px" height="80px" class="d-flex justify-content-start">
              </div>

              <div class="justify-content-center ms-2 mt-3" style="text-align: left; font-size: 14px;">
                <p class="d-flex" style="text-align: left;">Portal Sku : <span  [matTooltip]="status.portal_sku.length > 15 ? status.portal_sku : null"
                 > {{ status.portal_sku | slice:0:15 }}{{ status.portal_sku.length > 15
                  ? '...' : ''
                  }}</span></p>
                <p class="d-flex" style="text-align: left;">Ordered Quantity : {{status.qty}}</p>
                <p class="d-flex" style="text-align: left;">Sale Price : {{status.sale_price}}</p>
                <p class="d-flex" style="text-align: left;">Available Stock : {{status.available_stock}}</p>

              </div>
            </div>

          </div>
        

        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.superuser=='false' && admin1 === false">
    <div class="col-md-12">
      <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="card-body" *ngIf="salesAnalytics">
          <div class="card-title d-flex justify-content-between">
            <h4 class="card-title mb-2">Shipping Details Analysis</h4>
            <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(30)"
                [ngClass]="{'active': content1 === 30}">Last 30 Days</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(60)"
                [ngClass]="{'active': content1 === 60}">Last 60 Days</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(90)"
                [ngClass]="{'active': content1 === 90}">Last 90 Days</button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="chart-data mt-3" *ngFor="let label of salesAnalytics.labels; let i = index">
                <div class="label-item" *ngIf="salesAnalytics.series[i] !== 0">
                  <div class="color-indicator" *ngIf="salesAnalytics.series[i] !== 0"
                    [style.backgroundColor]="salesAnalytics.colors[i]"></div>
                  <div>
                    <span class="label-text">{{ label }}:</span>
                    <span class="value-text">{{ salesAnalytics.series[i] }}</span>

                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-7">
              <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
                [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="salesAnalytics.labels"
                [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
              </apx-chart>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="this.superuser=='true' && admin1 === false">
    <div class="col-md-8">
      <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="card-body" *ngIf="salesAnalytics">
          <div class="card-title d-flex justify-content-between">
            <h4 class="card-title mb-2">Shipping Details Analysis</h4>
            <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(30)"
                [ngClass]="{'active': content1 === 30}">Last 30 Days</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(60)"
                [ngClass]="{'active': content1 === 60}">Last 60 Days</button>
              <button type="button" class="btn btn-sm btn-light" (click)="fetchdata1(90)"
                [ngClass]="{'active': content1 === 90}">Last 90 Days</button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="chart-data mt-3" *ngFor="let label of salesAnalytics.labels; let i = index">
                <div class="label-item" *ngIf="salesAnalytics.series[i] !== 0">
                  <div class="color-indicator" *ngIf="salesAnalytics.series[i] !== 0"
                    [style.backgroundColor]="salesAnalytics.colors[i]"></div>
                  <div>
                    <span class="label-text">{{ label }}:</span>
                    <span class="value-text">{{ salesAnalytics.series[i] }}</span>

                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-7">
              <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
                [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="salesAnalytics.labels"
                [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
              </apx-chart>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card p-3" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="card-title mt-4 d-flex  justify-content-between"  *ngIf="this.tickets_valueee">
          <h4>Ticket Summary</h4>
          <div>
            <button type="button" class="btn btn-sm me-2" [class.btn-light]="activeButton !== 'ticket'" [class.btn-primary]="activeButton === 'ticket'" (click)="setActiveButton('ticket')">Ticket</button>
            <button type="button" class="btn btn-sm" [class.btn-light]="activeButton !== 'refund'" [class.btn-primary]="activeButton === 'refund'" (click)="setActiveButton('refund')">Refund</button>
          </div>
        </div>
        <div class="card-title mt-3 d-flex  justify-content-between"  *ngIf="!this.tickets_valueee">
          <h4>Refund Summary</h4>
          <div>
            <button type="button" class="btn btn-sm me-2" [class.btn-light]="activeButton !== 'ticket'" [class.btn-primary]="activeButton === 'ticket'" (click)="setActiveButton('ticket')">Ticket</button>
            <button type="button" class="btn btn-sm" [class.btn-light]="activeButton !== 'refund'" [class.btn-primary]="activeButton === 'refund'" (click)="setActiveButton('refund')">Refund</button>

          </div>
         
        </div>
        <div class="card-body p-4" style="height: 300px; overflow-y: auto;">
          <div *ngFor="let key of (getvalues | keyvalue)">
            <div class="d-flex justify-content-between mt-2 pt-1">
              <h6 style="font-size: 19px;">{{ key.key | titlecase }}</h6> <!-- Key (product name) -->
              <h6 style="font-size: 19px;"> : {{ key.value }}</h6> <!-- Value -->
            </div>
          </div>
        </div>        
      </div>
    



    </div>
  </div>
  <div class="row" *ngIf="this.superuser=='true' && admin1 === false" style="display: none;">
    <div class="col-md-12">
      <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between laptop">
            <h4 class="card-title">Target  Details Analysis</h4>
            <div>
              <form>
                  <div class="d-flex justify-content-center text-center mx-auto">
                      <div class="col-md-6 me-3">
                          <mat-form-field appearance="outline" class="col-md-12">
                              <mat-label>Month and Year</mat-label>
                              <input matInput [matDatepicker]="dp" [formControl]="data1">
                              <mat-hint>MM/YYYY</mat-hint>
                              <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                              <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                  (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                              </mat-datepicker>
                          </mat-form-field>
                      </div>
                      <div class="col-md-2 mt-1">
                          <button class="bg-primary" (click)="target_view()"
                              style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>
  
                      </div>
  
                  </div>
              </form>
          </div>
           
          </div>
           <div class="card-title  justify-content-between mobile">
            <h4 class="card-title">Target  Details Analysis</h4>
            <div>
              <form>
                  <div class="justify-content-center text-center mx-auto mt-3">
                      <div class="col-md-6 me-3">
                          <mat-form-field appearance="outline" class="col-md-12">
                              <mat-label>Month and Year</mat-label>
                              <input matInput [matDatepicker]="dp" [formControl]="data1">
                              <mat-hint>MM/YYYY</mat-hint>
                              <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                              <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                  (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                              </mat-datepicker>
                          </mat-form-field>
                      </div>
                      <div class="col-md-2 mt-1">
                          <button class="bg-primary" (click)="target_view()"
                              style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>
  
                      </div>
  
                  </div>
              </form>
          </div>
           
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">

                  <div class="d-flex justify-content-end ms-5">
                    <div>
                      <h4 class="card-title mb-2 me-5">Target <span style="margin-left: 24px;">:</span><span style="color: red; margin-left: 8px;">{{this.target}}</span></h4>
                      <h4 class="card-title me-5">Achieved<span style="margin-left: 8px;">:</span><span style="color: green; margin-left: 8px;">{{this.achived}}</span></h4>
                    </div>
            
                  </div>
            
                  <!-- Line, column & Area chart-->
                  <apx-chart class="apex-charts" dir="ltr" [series]="lineColumAreaChart.series"
                    [chart]="lineColumAreaChart.chart" [tooltip]="lineColumAreaChart.tooltip" [yaxis]="lineColumAreaChart.yaxis"
                    [legend]="lineColumAreaChart.legend" [plotOptions]="lineColumAreaChart.plotOptions"
                    [grid]="lineColumAreaChart.grid" [stroke]="lineColumAreaChart.stroke" [xaxis]="lineColumAreaChart.xaxis"
                    [colors]="lineColumAreaChart.colors" [fill]="lineColumAreaChart.fill" [labels]="lineColumAreaChart.labels"
                    [markers]="lineColumAreaChart.markers">
                  </apx-chart>
                </div>
              </div>
              <!--end card-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card p-3" *ngIf="admin1 === true">
  <div class="row">
    <div class="d-flex justify-content-between">
      <h4 class="card-title mb-4">Shipping Details</h4>
      <div>
        <select [(ngModel)]="selectedOption1" (change)="onSelectChange1($event)" id="select" class="form-select">
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="last_7_days">Last 7 Days</option>
          <option value="last_15_days">Last 15 Days</option>
          <option value="custom">Custom</option>
        </select>
        <div *ngIf="formData00 === true" class="mt-4">
          <form>
              <div class="d-flex justify-content-center text-center mx-auto">
                  <div class="col-md-6 me-3">
                      <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Month and Year</mat-label>
                          <input matInput [matDatepicker]="dp" [formControl]="data3">
                          <mat-hint>MM/YYYY</mat-hint>
                          <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                          <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler2($event)"
                              (monthSelected)="chosenMonthHandler2($event, dp)" panelClass="example-month-picker">
                          </mat-datepicker>
                      </mat-form-field>
                  </div>
                  <div class="col-md-2 mt-1">
                      <button class="bg-primary" (click)="bulk_status_details3()"
                          style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>

                  </div>

              </div>
          </form>
      </div>
      </div>
    </div>    
    <div class="col-md-3">
      <div class="chart-data mt-3" *ngFor="let label of salesAnalytics.labels; let i = index">
        <div class="label-item" *ngIf="salesAnalytics.series[i] !== 0">
          <div class="color-indicator" *ngIf="salesAnalytics.series[i] !== 0"
            [style.backgroundColor]="salesAnalytics.colors[i]"></div>
          <div>
            <span class="label-text">{{ label }}:</span>
            <span class="value-text">{{ salesAnalytics.series[i] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 mt-4">
      <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
        [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="salesAnalytics.labels"
        [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
      </apx-chart>
    </div>
  </div>
</div>


<div class="col-lg-12" *ngIf="admin1 === true">
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h4 class="card-title mb-4">Portalwise Shipping Details</h4>
        <div>
          <select [(ngModel)]="selectedOption" (change)="onSelectChange($event)" id="select" class="form-select">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last_7_days">Last 7 Days</option>
            <option value="last_15_days">Last 15 Days</option>
            <option value="custom">Custom</option>
          </select>
          <div *ngIf="formData99 === true" class="mt-4">
            <form>
                <div class="d-flex justify-content-center text-center mx-auto">
                    <div class="col-md-6 me-3">
                        <mat-form-field appearance="outline" class="col-md-12">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="dp" [formControl]="data2">
                            <mat-hint>MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler1($event)"
                                (monthSelected)="chosenMonthHandler1($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 mt-1">
                        <button class="bg-primary" (click)="bulk_status_details1()"
                            style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>

                    </div>

                </div>
            </form>
        </div>
        </div>
      </div>
    

      <apx-chart class="apex-charts" dir="ltr" 
      [series]="basicColumChart.series" 
      [chart]="basicColumChart.chart"
      [plotOptions]="basicColumChart.plotOptions" 
      [yaxis]="basicColumChart.yaxis" 
      [grid]="basicColumChart.grid"
      [tooltip]="basicColumChart.tooltip" 
      [stroke]="basicColumChart.stroke"
      [dataLabels]="basicColumChart.dataLabels" 
      [xaxis]="basicColumChart.xaxis" 
      [colors]="basicColumChart.colors"
      [fill]="basicColumChart.fill">
</apx-chart>
    </div>
  </div>
  <!--end card-->
</div>
<div class="row" *ngIf="this.superuser=='true'&& admin1 === true ">
  <div class="col-md-12">
    <div class="card" style="box-shadow: 1px 1px 1px 1px grey">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between laptop">
          <h4 class="card-title">Target  Details Analysis</h4>
          <div>
            <form>
                <div class="d-flex justify-content-center text-center mx-auto">
                    <div class="col-md-6 me-3">
                        <mat-form-field appearance="outline" class="col-md-12">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="dp" [formControl]="data1">
                            <mat-hint>MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 mt-1">
                        <button class="bg-primary" (click)="target_view()"
                            style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>

                    </div>

                </div>
            </form>
        </div>
         
        </div>
         <div class="card-title  justify-content-between mobile">
          <h4 class="card-title">Target  Details Analysis</h4>
          <div>
            <form>
                <div class="justify-content-center text-center mx-auto mt-3">
                    <div class="col-md-6 me-3">
                        <mat-form-field appearance="outline" class="col-md-12">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="dp" [formControl]="data1">
                            <mat-hint>MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 mt-1">
                        <button class="bg-primary" (click)="target_view()"
                            style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px;">Submit</button>

                    </div>

                </div>
            </form>
        </div>
         
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">

                <div class="d-flex justify-content-end ms-5">
                  <div>
                    <h4 class="card-title mb-2 me-5">Target <span style="margin-left: 24px;">:</span><span style="color: red; margin-left: 8px;">{{this.target11}}</span></h4>
                    <h4 class="card-title me-5">Achieved<span style="margin-left: 8px;">:</span><span style="color: green; margin-left: 8px;">{{this.achived}}</span></h4>
                  </div>
          
                </div>
          
                <!-- Line, column & Area chart-->
                <apx-chart class="apex-charts" dir="ltr" [series]="lineColumAreaChart.series"
                  [chart]="lineColumAreaChart.chart" [tooltip]="lineColumAreaChart.tooltip" [yaxis]="lineColumAreaChart.yaxis"
                  [legend]="lineColumAreaChart.legend" [plotOptions]="lineColumAreaChart.plotOptions"
                  [grid]="lineColumAreaChart.grid" [stroke]="lineColumAreaChart.stroke" [xaxis]="lineColumAreaChart.xaxis"
                  [colors]="lineColumAreaChart.colors" [fill]="lineColumAreaChart.fill" [labels]="lineColumAreaChart.labels"
                  [markers]="lineColumAreaChart.markers">
                </apx-chart>
              </div>
            </div>
            <!--end card-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>