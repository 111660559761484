<div class="container col-md-12">
    <div class="">
      <div class="d-flex justify-content-between mb-5">
        <h1 class="page col-md-6 mb-4" >Alternative Orders</h1>
        <!-- <button class="bg-primary p-1" *ngIf="d2c_name === 'woocommerce' && superuser === 'true'" style="border: 0px; border-radius: 5px; color: white;" (click)="openVerticallyCentered1(content2)">Create Order</button> -->
        <div>
          <button class="bg-primary p-1 me-3"  style="border: 0px; border-radius: 5px; color: white; height: 36px;"  (click)="openVerticallyCentered5(content2)">Create Alternative Order</button>
        </div>       
        
      </div>
      <!-- <div class="">
              <mat-form-field appearance="outline">
                  <mat-label>Select</mat-label>
                  <mat-select [(ngModel)]="selectedOption"> 
                      <mat-option (click)="setplaceholder('Search')">Clear</mat-option> 
                      <mat-option value="order_id" (click)="setplaceholder('Search For Order id')">Order Id</mat-option>
                      <mat-option value="awb" (click)="setplaceholder('Search For Awb')">Awb</mat-option> 
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{placeholder_name}}</mat-label>
                  <input matInput placeholder="" value="" [(ngModel)]="searchTerm"  (keyup)="applyFilter($event.target.value)" [disabled]="!isOptionSelected">
              </mat-form-field>
              <div *ngIf="!isCancelled" style="margin-top: -15px;">              
                  <div *ngFor="let item of searchdata" class="card p-4">
                    <div class="card-header">
                      <h2>Order ID: {{ item.order_id }}</h2>
                    </div>
                    <div class="card-body">
                      <p><strong>Order Date:</strong> {{ item.order_date }}</p>
                      <p><strong>Dispatch By Date:</strong> {{ item.dispatch_by_date }}</p>
                      <p><strong>Selling Price:</strong> {{ item.selling_price }}</p>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button class="btn-danger button" (click)="cancelSearch()">Cancel</button>
                    </div>
                  </div>
                
              </div>
                
                
          </div> -->
  
    </div>
    <!-- <div class="d-flex justify-content-end laptop">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControl]="start_date" placeholder="Start date"
              (dateInput)="onStartDateSelected($event)">
            <input matEndDate [formControl]="end_date" placeholder="End date" [disabled]="end_date.disabled">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
  
        </mat-form-field>
        <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
          <mat-error>End date must be within 30 days from the start date.</mat-error>
        </div>
      </div>
      <button class="bg-primary mt-1 me-4 ms-4"
        style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
        (click)="fetchData1()" [disabled]="!start_date.valid || !end_date.valid">
        Submit
      </button>
  
  
  
    </div>
    <div class="justify-content-center mobile text-center">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControl]="start_date" placeholder="Start date"
              (dateInput)="onStartDateSelected($event)">
            <input matEndDate [formControl]="end_date" placeholder="End date" [disabled]="end_date.disabled">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
  
        </mat-form-field>
        <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
          <mat-error>End date must be within 30 days from the start date.</mat-error>
        </div>
      </div>
      <button class="bg-primary mt-1 me-4 ms-4"
        style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
        (click)="fetchData1()" [disabled]="!start_date.valid || !end_date.valid">
        Submit
      </button>
  
  
  
    </div> -->
    <div class="d-flex justify-content-between mt-5 laptop">
      <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."   (keydown.enter)="applyFilter1($event.target.value)"
>
        <span class="ri-search-line search-icon"></span>
      </div>
  
      <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
      </div>
      <!-- <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="date_wise && searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData2($event)"></mat-paginator>
      </div> -->
      <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === true">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
      </div>
    </div>
    <div class="justify-content-center mt-5 text-center mobile">
      <!-- <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
          (keyup)="applyFilter1($event.target.value)">
        <span class="ri-search-line search-icon"></span>
      </div> -->
  
      <!-- <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="!date_wise && searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
      </div>
      <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="date_wise && searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData2($event)"></mat-paginator>
      </div>
      <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === true">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
      </div> -->
    </div>
  
  
  
    <div class="row d-flex justify-content-center p-4">
      <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
          <div class="position-relative overflow-auto">
            <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers">
              <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i">
                <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold"
                  style="width: auto!important; white-space:nowrap;">
                  <span *ngIf="headerData[tableheader] !== 'Status' && headerData[tableheader] !== 'select'" >{{ headerData[tableheader] }}</span>
                  
                  <div style="display: flex;">
                    <span *ngIf="headerData[tableheader] === 'Status'" >{{ headerData[tableheader] }}</span>
                  </div>
                </th>
                <!-- <ng-container *ngIf="tableheader=='actions'">
                  <td mat-cell class=" " *matCellDef="let row; index as i" style="font-size: 12px;">
                    <div class="d-flex">
                        <button class="btn btn-primary btn-sm ms-2" >
                  Edit
                </button>
                <button class="btn btn-primary btn-sm ms-3"   style="width: 100px;">
                  Close Dispute
                </button>
                
  
  
                    </div>
                  </td>
                </ng-container> -->
  
                <ng-container *ngIf="tableheader !== 'actions'">
                  <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                    *matCellDef="let row; index as i">
                    <span>{{ row[tableheader] }}</span>
                  </td>
                </ng-container>
              </ng-container>
  
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
  
          </div>
  
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === false">
          <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="fulllength"
            [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === true">
          <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="fulllength"
            [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
        </div>
      </div>
  
  
    </div>
  
  </div>


  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Create Alternative Order</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="col-md-12 mb-3">
        <label for="order_id">Order Id</label>
        <input type="text"  class="form-control" id="order_id" placeholder="Enter Order Id"  [formControl]="orderid"
        />
      </div>
    
      <div class="d-flex justify-content-end me-2 mt-4">
        <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"  [disabled]="orderid.value === ''" (click)="get_clone_orders(content3)"
  >Submit</button>
      </div>
  
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
    </ng-container>
  </ng-template>


  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Create Alternative Order</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-4">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start">
          <p style="color: #84878a;"><strong style="font-weight: 700; color: black; font-size: 18px;">Original Order Id : </strong>{{orderid.value}}</p>
          <p style="color: #84878a;"><strong style="font-weight: 700; color: black; font-size: 18px;">Alternative Order Id : </strong>ALT_{{orderid.value}}</p>
        </div>
        <div class="justify-content-start mb-3">
          <p style="color: #84878a;"><strong style="font-weight: 700; color: black; font-size: 18px;">Payment Method : </strong>{{apiresponse[0].payment_method}}</p>
          <p style="color: #84878a;"><strong style="font-weight: 700; color: black; font-size: 18px;">Warehouse Id : </strong>{{apiresponse[0].warehouse_id}}</p>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="position-relative col-md-3 mb-3">
          <input type="text" class="form-control search-input" placeholder="Search Buymore Sku & Portal Sku"
            (keyup)="applyFilter3($event.target.value)">
          <span class="ri-search-line search-icon"></span>
        </div>
  
        <div class="d-flex justify-content-end">
          <button class="bg-primary p-1 me-3 mb-3"  style="border: 0px; border-radius: 5px; color: white; height: 36px;" *ngIf="selectedEntries.length>0" (click)="openVerticallyCentered6(partialrefund1)" >Create Bulk Alternative Order</button>
        </div>
      </div>

      <table class="styled-table">
        <thead>
            <tr>
                <!-- Table Headers -->
                <!-- <th>Order ID</th> -->
                 <th><div> <input class="form-check-input me-3" type="checkbox"  (change)="toggleAllRows($event)"  [checked]="selectAllChecked"  >Select All</div></th>
                <th>Order Item ID</th>
                <!-- <th>Payment Method</th> -->
                <!-- <th>Warehouse ID</th> -->
                <th>Buymore SKU</th>
                <th>Portal SKU</th>
                <th>Quantity</th>
                <th>Action</th>

                <!-- <th>Portal ID</th>
                <th>Portal Account ID</th> -->
            </tr>
        </thead>
        <tbody>
            <!-- Table Rows -->
            <tr *ngFor="let item of apiresponse; let i = index">
              <th scope="row">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                    [checked]="item.selected==true"   class="custom-checkbox"  (change)="filterBasedStatus1(item, $event)">
                </div>
              </th>
                <!-- <td>{{ item.order_id }}</td> -->
                <td>{{ item.order_item_id }}</td>
                <!-- <td>{{ item.payment_method }}</td>
                <td>{{ item.warehouse_id }}</td> -->
                <td>{{ item.buymore_sku }}</td>
                <td>{{ item.portal_sku }}</td>
                <!-- <td>{{ item.qty }}</td> -->
                <td>
                  <div *ngIf="!item.isEditing">
                    <span>{{ item.qty }}</span>
                    <i
                      class="fas fa-edit"
                      style="cursor: pointer; color: #007bff; margin-left: 10px;"
                      (click)="editItem(item)"
                    ></i>
                  </div>
                  <div *ngIf="item.isEditing">
                    <input
                      type="number"
                      [(ngModel)]="item.qty"
                      class="form-control"
                      style="display: inline-block; width: 70%;"
                      min="1"
                      (input)="validateQuantity(item)"
                    />
                    <i
                      class="fas fa-check"
                      style="cursor: pointer; color: green; margin-left: 10px;"
                      (click)="saveChanges(item)"
                      
                    ></i>
                    <div *ngIf="item.qty <= 0" class="text-danger mt-2">
                      Quantity must be greater than 0.
                    </div>
                  </div>
                </td>
                               
                <td>
                  <img src="assets/images/edit.png" width="30px" height="30px" style="cursor: pointer;"  *ngIf="selectedEntries.length === 0" (click)="openVerticallyCentered8(partialrefund2,item)">
                  <img src="assets/images/delete.png" width="30px" height="30px" class="ms-3" style="cursor: pointer;" (click)="openVerticallyCentered(content5,item)" >
                </td>
                <!-- <td>{{ item.portal_id }}</td>
                <td>{{ item.portal_account_id }}</td> -->
            </tr>
        </tbody>
    </table>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
    </ng-container>
  </ng-template>


  <ng-template #content5 let-modal>
    <div class="modal-header">
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
         <img src="assets/images/warning.png">

      </div>
      <h3 class="d-flex justify-content-center">Are You Sure !</h3>
      <p class="d-flex justify-content-center">You want to delete this order</p>
    </div>
    <div class="modal-footer d-flex justify-content-center">
    
     <button type="button" class="btn btn-success"  style="width: 80px;"  (click)="deleteItem()">Yes</button>
      <button type="button" class="btn btn-danger" (click)="modal.close('Save click')" style="width: 80px;">No</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
   </ng-container>
</ng-template>


<ng-template #partialrefund1 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Create Alternative Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform8">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="alt_order_id">Alternative Order Id</label>
              <input type="text" formControlName="alternative_order_id" class="form-control" id="awb"  [(ngModel)]="alt_order_id"
                placeholder="Enter Alternative Order Id"  />
              <div
                *ngIf="createuserform8!.get('alternative_order_id')?.invalid && createuserform8!.get('alternative_order_id')?.touched"
                class="text-danger">
                Alternative Order Id field is required
              </div>
            </div>
            <div class="col-md-6">
              <label for="alternative_payment_method">Alternative Payment Method</label>
              <select formControlName="alternative_payment_method" class="form-select" id="alternative_payment_method" [(ngModel)]="apiresponse[0].payment_method">
                <option value="Prepaid">Prepaid</option>
                <option value="COD">COD</option>
              </select>
              <div *ngIf="createuserform8!.get('alternative_payment_method')?.invalid && createuserform8!.get('alternative_payment_method')?.touched"
                class="text-danger">
                Alternative Payment Method field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="warehouse_id">Alternative Warehouse Id</label>
              <select formControlName="warehouse_id" class="form-select" id="warehouse_id" [(ngModel)]="apiresponse[0].warehouse_id">
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="7">7</option>
                <option value="10">10</option>
                <option value="12">12</option>
              </select>
              <div
                *ngIf="createuserform8!.get('warehouse_id')?.invalid && createuserform8!.get('warehouse_id')?.touched"
                class="text-danger">
                Warehouse Id field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="reason">Reason</label>
              <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Enter Reason"
              />
              <div *ngIf="createuserform8!.get('reason')?.invalid && createuserform8!.get('reason')?.touched"
                class="text-danger">
               Reason field is required
              </div>
            </div>
             
            </div>
            <!-- <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
              <div class="col-md-12 mb-3">
                <label for="reason">Reason</label>
                <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Enter Reason"
                />
                <div *ngIf="createuserform8!.get('reason')?.invalid && createuserform8!.get('reason')?.touched"
                  class="text-danger">
                 Reason field is required
                </div>
              </div>
              
            </div> -->
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="update_clone_orders()"    [disabled]="createuserform8.invalid" >Submit</button>
    </div>

  </div>
  <ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
  </ng-container>
</ng-template>


<ng-template #partialrefund2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Create Alternative Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p style="color: green;">Quantity will be updated for the Buymore Sku {{create_data.buymore_sku}}</p>
    <form [formGroup]="createuserform8">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="alt_order_id">Alternative Order Id</label>
              <input type="text" formControlName="alternative_order_id" class="form-control" id="awb"  [(ngModel)]="alt_order_id"
                placeholder="Enter Alternative Order Id"  />
              <div
                *ngIf="createuserform8!.get('alternative_order_id')?.invalid && createuserform8!.get('alternative_order_id')?.touched"
                class="text-danger">
                Alternative Order Id field is required
              </div>
            </div>
            <div class="col-md-6">
              <label for="alternative_payment_method">Alternative Payment Method</label>
              <select formControlName="alternative_payment_method" class="form-select" id="alternative_payment_method" [(ngModel)]="create_data.payment_method">
                <option value="Prepaid">Prepaid</option>
                <option value="COD">COD</option>
              </select>
              <div *ngIf="createuserform8!.get('alternative_payment_method')?.invalid && createuserform8!.get('alternative_payment_method')?.touched"
                class="text-danger">
                Alternative Payment Method field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
              <div class="col-md-6 mb-3">
                <label for="quantity">Quantity</label>
                <input type="number" formControlName="quantity" class="form-control" id="quantity" placeholder="Enter Quantity" min="1" [(ngModel)]="create_data.qty"
                />
                <div *ngIf="createuserform8!.get('quantity')?.invalid && createuserform8!.get('quantity')?.touched"
                  class="text-danger">
                 Quantity should be greater than zero
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="warehouse_id">Alternative Warehouse Id</label>
                <select formControlName="warehouse_id" class="form-select" id="warehouse_id" [(ngModel)]="create_data.warehouse_id">
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="7">7</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                </select>
                <div
                  *ngIf="createuserform8!.get('warehouse_id')?.invalid && createuserform8!.get('warehouse_id')?.touched"
                  class="text-danger">
                  Warehouse Id field is required
                </div>
              </div>
            </div>
            <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
              <div class="col-md-12 mb-3">
                <label for="reason">Reason</label>
                <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Enter Reason"
                />
                <div *ngIf="createuserform8!.get('reason')?.invalid && createuserform8!.get('reason')?.touched"
                  class="text-danger">
                 Reason field is required
                </div>
              </div>
              
            </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="update_clone_orders1()"    [disabled]="createuserform8.invalid" >Submit</button>
    </div>

  </div>
  <ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
  </ng-container>
</ng-template>
  
  <ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
  </ng-container>
