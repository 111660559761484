<div class="container col-md-12">
  <div class="">
    <div class="d-flex justify-content-between mb-5">
      <h1 class="page col-md-6 mb-4">Orders List</h1>
      <button class="bg-primary p-1" *ngIf="d2c_name === 'woocommerce' && superuser === 'true'" style="border: 0px; border-radius: 5px; color: white;" (click)="openVerticallyCentered1(content2)">Create Order</button>
      <button class="bg-primary p-1" *ngIf="d2c_name === 'shopify' && superuser === 'true'" style="border: 0px; border-radius: 5px; color: white;" (click)="openVerticallyCentered1(content1)">Create Order</button>
     
      
    </div>
    <!-- <div class="">
            <mat-form-field appearance="outline">
                <mat-label>Select</mat-label>
                <mat-select [(ngModel)]="selectedOption"> 
                    <mat-option (click)="setplaceholder('Search')">Clear</mat-option> 
                    <mat-option value="order_id" (click)="setplaceholder('Search For Order id')">Order Id</mat-option>
                    <mat-option value="awb" (click)="setplaceholder('Search For Awb')">Awb</mat-option> 
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{placeholder_name}}</mat-label>
                <input matInput placeholder="" value="" [(ngModel)]="searchTerm"  (keyup)="applyFilter($event.target.value)" [disabled]="!isOptionSelected">
            </mat-form-field>
            <div *ngIf="!isCancelled" style="margin-top: -15px;">              
                <div *ngFor="let item of searchdata" class="card p-4">
                  <div class="card-header">
                    <h2>Order ID: {{ item.order_id }}</h2>
                  </div>
                  <div class="card-body">
                    <p><strong>Order Date:</strong> {{ item.order_date }}</p>
                    <p><strong>Dispatch By Date:</strong> {{ item.dispatch_by_date }}</p>
                    <p><strong>Selling Price:</strong> {{ item.selling_price }}</p>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button class="btn-danger button" (click)="cancelSearch()">Cancel</button>
                  </div>
                </div>
              
            </div>
              
              
        </div> -->

  </div>
  <div class="d-flex justify-content-end laptop">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [formControl]="start_date" placeholder="Start date"
            (dateInput)="onStartDateSelected($event)">
          <input matEndDate [formControl]="end_date" placeholder="End date" [disabled]="end_date.disabled">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

      </mat-form-field>
      <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
        <mat-error>End date must be within 30 days from the start date.</mat-error>
      </div>
    </div>
    <button class="bg-primary mt-1 me-4 ms-4"
      style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
      (click)="fetchData1()" [disabled]="!start_date.valid || !end_date.valid">
      Submit
    </button>



  </div>
  <div class="justify-content-center mobile text-center">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [formControl]="start_date" placeholder="Start date"
            (dateInput)="onStartDateSelected($event)">
          <input matEndDate [formControl]="end_date" placeholder="End date" [disabled]="end_date.disabled">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

      </mat-form-field>
      <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
        <mat-error>End date must be within 30 days from the start date.</mat-error>
      </div>
    </div>
    <button class="bg-primary mt-1 me-4 ms-4"
      style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
      (click)="fetchData1()" [disabled]="!start_date.valid || !end_date.valid">
      Submit
    </button>



  </div>
  <div class="d-flex justify-content-between mt-5 laptop">
    <div class="position-relative col-md-3">
      <input type="text" class="form-control search-input" placeholder="Search...."
        (keydown.enter)="applyFilter1($event.target.value)">
      <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="!date_wise && searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="date_wise && searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData2($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === true">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
    </div>
  </div>
  <div class="justify-content-center mt-5 text-center mobile">
    <div class="position-relative col-md-3">
      <input type="text" class="form-control search-input" placeholder="Search...."
        (keyup)="applyFilter1($event.target.value)">
      <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="!date_wise && searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="date_wise && searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData2($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === true">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
    </div>
  </div>



  <div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
      <div class="material-datatables">
        <div class="position-relative overflow-auto">
          <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers">
            <!-- ID Column -->
            <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i">
              <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold"
                style="width: auto!important;">
                <span>{{ headerData[tableheader] }}</span>
                <span *ngIf="headerData[tableheader] == 'Status'" class="filter-icon">
                  <i class="fa fa-filter pl-2" (click)="openDropdown()" style="font-size: 10px; cursor: pointer;"></i>
                  <mat-select #select  [formControl]="vendorForm" style="border: none; background: none; -webkit-appearance: none; -moz-appearance: none; appearance: none;" class="hide-arrow">
                      <!-- <mat-form-field class="p-2">
                          <div class="d-flex justify-content-between cursor">
                              <input matInput autocomplete="off" (input)="applyFilterVendor($event.target.value)" />
                          </div>
                      </mat-form-field> -->
                      <mat-option *ngFor="let zone of filteredPortal" (click)="closedFilter()" [value]="zone.status">
                          {{ zone.status }}
                      </mat-option>
                  </mat-select>
              </span>
                <!-- {{ headerData[tableheader] }} -->
              </th>
              <ng-container *ngIf="tableheader=='actions'">
                <td mat-cell class=" " *matCellDef="let row; index as i" style="font-size: 12px;">
                  <div class="d-flex">
                    <a href="{{row.tracking_url}}" target="_blank"><button
                        class="btn btn-primary btn-sm me-2" style="width: 110px;">Track Order</button>
                    </a>
                    <button class="btn btn-primary btn-sm me-2" style="width: 110px;"
                      (click)="openVerticallyCentered(content,row)">Raise Tickets</button>
                    <button class="btn btn-danger btn-sm" (click)="cancel_order(row)"
                      [disabled]="row.disableButton">Cancel/Return</button>
                      <button class="btn btn-primary btn-sm ms-2" *ngIf="superuser === 'true'"  (click)="openVerticallyCentered2(updateorder,row)"
                      [disabled]="row.status !== 'shipment_issue' && row.status !== 'Payment Success' && row.status !== 'Payment Pending'">
                Edit
              </button>
              


                  </div>
                </td>
              </ng-container>

              <ng-container *ngIf="tableheader !== 'actions'">
                <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                  *matCellDef="let row; index as i">
                  <span>{{ row[tableheader] }}</span>
                </td>
              </ng-container>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

        </div>

      </div>
      <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="!date_wise && searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="fulllength"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
      </div>
      <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="date_wise && searchchar === false">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="fulllength"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData2($event)"></mat-paginator>
      </div>
      <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === true">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="fulllength"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData3($event,value)"></mat-paginator>
      </div>
    </div>


  </div>

</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Raise Ticket for Order Id {{this.headerid}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform">
      <div class="row">
        <div>
          <div class="col-md-12 mb-3">
            <label for="reason">Agent Remark</label>
            <input type="text" formControlName="agent_remark" class="form-control" id="Reason" placeholder="Agent Remark"   (blur)="only_spl_and_num_not_accept($event,'Agent Remark')"
            />
            <div *ngIf="createuserform!.get('agent_remark')?.invalid && createuserform!.get('agent_remark')?.touched"
              class="text-danger">
              Agent Remark field is required
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <label for="voice_of_customer">Voice Of Customer</label>
            <input type="text" formControlName="voice_of_customer" class="form-control" id="voice_of_customer"
              placeholder="Voice Of Customer" (blur)="only_spl_and_num_not_accept($event,'Voice Of Customer')" />
            <div
              *ngIf="createuserform!.get('voice_of_customer')?.invalid && createuserform!.get('voice_of_customer')?.touched"
              class="text-danger">
              Voice Of Customer field is required
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <label for="status">Category</label>
            <select formControlName="category" class="form-select" id="category">
              <option value="0" disabled selected>Select Category</option>
              <option value="missing order">Missing Order</option>
              <option value="wrong product dispatched">Wrong Product Dispatched</option>
              <option value="order not received">Order Not Received</option>
              <option value="missing quantity">Missing Quantity</option>
              <option value="wrong invoice shared">Wrong Invoice Shared</option>
              <option value="order delay">Order Delay</option>
              <option value="other">Other</option>
            </select>
            <div
            *ngIf="createuserform!.get('category')?.invalid && createuserform!.get('category')?.touched"
            class="text-danger">
            Category field is required
          </div>
          </div>
          <!-- <div class="col-md-12 mb-3">
                        <label for="status">Status</label>
                        <select formControlName="status" class="form-select" id="status1">
                          <option value="open">Open</option>
                          <option value="close">Close</option>
                        </select>
                      </div> 
                      <div class="col-md-12">
                        <label for="disposition">Disposition</label>
                        <select formControlName="disposition" class="form-select" id="disposition">
                          <option value="pending">Pending</option>
                          <option value="accepted">Accepted</option>
                          <option value="rejected">Rejected</option>
                          <option value="in progress">In Progress</option>
                          <option value="resolved">Resolved</option>
                        </select>
                      </div>  -->
          <div class="col-md-12 mb-3">
            <label for="support_chart">Leave A Note</label>
            <input type="text" formControlName="support_chat" class="form-control" id="support_chat" (blur)="only_spl_and_num_not_accept($event,'Leave A Note')"
              placeholder="Leave A Note" />
            <div *ngIf="createuserform!.get('support_chat')?.invalid && createuserform!.get('support_chat')?.touched"
              class="text-danger">
              Leave A Note filed is required
            </div>
          </div>
        </div>

      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
        [disabled]="!createuserform.valid" (click)="ticket_create()">Submit</button>
    </div>

  </div>
  <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
</ng-template>
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Create Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform1">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="first_name">First Name</label>
              <input type="text" formControlName="first_name" class="form-control" id="first_name" placeholder="First Name" 
              />
              <div *ngIf="createuserform1!.get('first_name')?.invalid && createuserform1!.get('first_name')?.touched"
                class="text-danger">
                First Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Last Name</label>
              <input type="text" formControlName="last_name" class="form-control" id="last_name"
                placeholder="Last Name"  />
              <div
                *ngIf="createuserform1!.get('last_name')?.invalid && createuserform1!.get('last_name')?.touched"
                class="text-danger">
                Last Name field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="address1">Address 1</label>
              <input type="text" formControlName="address1" class="form-control" id="address1"
                placeholder="Enter Address 1"  />
              <div
                *ngIf="createuserform1!.get('address1')?.invalid && createuserform1!.get('address1')?.touched"
                class="text-danger">
                Address1 field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="address2">Address 2</label>
              <input type="text" formControlName="address2" class="form-control" id="address2"
                placeholder="Enter Address 2"  />
              <div
                *ngIf="createuserform1!.get('address2')?.invalid && createuserform1!.get('address2')?.touched"
                class="text-danger">
                Address2 field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="country">Country</label>
              <input type="text" formControlName="country" class="form-control" id="country"
                placeholder="Enter Country" />
              <div
                *ngIf="createuserform1!.get('country')?.invalid && createuserform1!.get('country')?.touched"
                class="text-danger">
                Country field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="state">State</label>
              <input type="text" formControlName="state" class="form-control" id="state"
                placeholder="Enter State"  />
              <div
                *ngIf="createuserform1!.get('state')?.invalid && createuserform1!.get('state')?.touched"
                class="text-danger">
                State field is required
              </div>
            </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="state">City</label>
              <input type="text" formControlName="city" class="form-control" id="city"
                placeholder="Enter City"  />
              <div
                *ngIf="createuserform1!.get('city')?.invalid && createuserform1!.get('city')?.touched"
                class="text-danger">
                City field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="pincode">Pincode</label>
              <input type="text" formControlName="pincode" class="form-control" id="pincode"  maxlength="6" minlength="6" (keypress)="numericOnly($event)"
                placeholder="Enter Pincode"  />
              <div
                *ngIf="createuserform1!.get('pincode')?.invalid && createuserform1!.get('pincode')?.touched"
                class="text-danger">
                Pincode field is required
              </div>
            </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            
          <div class="col-md-6 mb-3">
            <label for="email_id">Email Id</label>
            <input type="text" formControlName="email_id" class="form-control" id="email_id"
              placeholder="Enter Email Id"  />
            <div
              *ngIf="createuserform1!.get('email_id')?.invalid && createuserform1!.get('email_id')?.touched"
              class="text-danger">
              Email Id field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="phone">Phone</label>
            <input type="text" formControlName="phone" class="form-control" id="phone"  maxlength="10" minlength="10" (keypress)="numericOnly($event)"
              placeholder="Enter Phone" />
            <div
              *ngIf="createuserform1!.get('phone')?.invalid && createuserform1!.get('phone')?.touched"
              class="text-danger">
              Phone field is required
            </div>
          </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="title">Title</label>
              <input type="text" formControlName="title" class="form-control" id="title"
                placeholder="Enter Title"  />
              <div
                *ngIf="createuserform1!.get('title')?.invalid && createuserform1!.get('title')?.touched"
                class="text-danger">
                Title field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="shipping_charge">Shipping Charge</label>
              <input type="text" formControlName="shipping_charge" class="form-control" id="shipping_charge"
                placeholder="Enter Shipping Charge"  />
              <div
                *ngIf="createuserform1!.get('shipping_charge')?.invalid && createuserform1!.get('shipping_charge')?.touched"
                class="text-danger">
                Shipping Charge field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="code">Code</label>
              <input type="text" formControlName="code" class="form-control" id="code"
                placeholder="Enter Code"  />
              <div
                *ngIf="createuserform1!.get('code')?.invalid && createuserform1!.get('code')?.touched"
                class="text-danger">
                Code field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="financial_status">Financial Status</label>
              <select formControlName="financial_status" class="form-select" aria-label="Default select example" id="financial_status">
                <option value="" selected disabled>Select an option</option>
                <option value="pending">Pending</option>
                <option value="active">Active</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div  formArrayName="line_items" id="containerElement">
                <div  *ngIf="getItemFormArray() as itemFormArray">
                    <div *ngFor="let chlid of itemFormArray.controls;let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label for="product">Select Product Name</label>
                            <ng-select
  [items]="fullData22"
  placeholder="Select Product Name"
  bindValue="product_name"
  bindLabel="product_name"
  formControlName="product">
  
  <ng-template ng-optgroup-tmp let-item="item">
    {{ item.country || 'Unnamed group' }}
  </ng-template>
  
</ng-select>

                            <!-- <select class="form-select" aria-label="Default select example" formControlName="product"
                             >
                             <option value="" selected disabled>Select Product Name</option>
                              <option value="Select Product Name" disabled>Select Product Name</option>
                              <option *ngFor="let name of fullData22" [value]="name.product_name">
                                {{ name.product_name }}
                              </option>
                            </select> -->
                            
                            <div *ngIf="createuserform1!.get('product')?.invalid && createuserform1!.get('product')?.touched"
                              class="text-danger">
                              Product field is required
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="code">Quantity</label>
                            <input type="text" formControlName="quantity" class="form-control" id="quantity"
                              placeholder="Enter Quantity"  />
                          </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <img src="assets/images/minus.png" width="24px" height="24px" *ngIf="i > 0" (click)="removeItem(i)" class="mt-1">
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-3">
                       <img src="assets/images/add.png" width="24px" height="24px" class="mt-2" (click)="addItem()">
                </div>
            </div>
        </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="create_order()">Submit</button>
    </div>

  </div>
</ng-template>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Create Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform2">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="first_name">First Name</label>
              <input type="text" formControlName="first_name" class="form-control" id="first_name" placeholder="First Name" 
              />
              <div *ngIf="createuserform2!.get('first_name')?.invalid && createuserform2!.get('first_name')?.touched"
                class="text-danger">
                First Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Last Name</label>
              <input type="text" formControlName="last_name" class="form-control" id="last_name"
                placeholder="Last Name"  />
              <div
                *ngIf="createuserform2!.get('last_name')?.invalid && createuserform2!.get('last_name')?.touched"
                class="text-danger">
                Last Name field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="address1">Address 1</label>
              <input type="text" formControlName="address1" class="form-control" id="address1"
                placeholder="Enter Address 1"  />
              <div
                *ngIf="createuserform2!.get('address1')?.invalid && createuserform2!.get('address1')?.touched"
                class="text-danger">
                Address1 field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="address2">Address 2</label>
              <input type="text" formControlName="address2" class="form-control" id="address2"
                placeholder="Enter Address 2"  />
              <div
                *ngIf="createuserform2!.get('address2')?.invalid && createuserform2!.get('address2')?.touched"
                class="text-danger">
                Address2 field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="country">Country</label>
              <input type="text" formControlName="country" class="form-control" id="country"
                placeholder="Enter Country" />
              <div
                *ngIf="createuserform2!.get('country')?.invalid && createuserform2!.get('country')?.touched"
                class="text-danger">
                Country field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="state">State</label>
              <input type="text" formControlName="state" class="form-control" id="state"
                placeholder="Enter State"  />
              <div
                *ngIf="createuserform2!.get('state')?.invalid && createuserform2!.get('state')?.touched"
                class="text-danger">
                State field is required
              </div>
            </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="state">City</label>
              <input type="text" formControlName="city" class="form-control" id="city"
                placeholder="Enter City"  />
              <div
                *ngIf="createuserform2!.get('city')?.invalid && createuserform2!.get('city')?.touched"
                class="text-danger">
                City field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="pincode">Pincode</label>
              <input type="text" formControlName="pincode" class="form-control" id="pincode"  maxlength="6" minlength="6" (keypress)="numericOnly($event)"
                placeholder="Enter Pincode"  />
              <div
                *ngIf="createuserform2!.get('pincode')?.invalid && createuserform2!.get('pincode')?.touched"
                class="text-danger">
                Pincode field is required
              </div>
            </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            
          <div class="col-md-6 mb-3">
            <label for="email_id">Email Id</label>
            <input type="text" formControlName="email_id" class="form-control" id="email_id"
              placeholder="Enter Email Id"  />
            <div
              *ngIf="createuserform2!.get('email_id')?.invalid && createuserform2!.get('email_id')?.touched"
              class="text-danger">
              Email Id field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="phone">Phone</label>
            <input type="text" formControlName="phone" class="form-control" id="phone"  maxlength="10" minlength="10" (keypress)="numericOnly($event)"
              placeholder="Enter Phone" />
            <div
              *ngIf="createuserform2!.get('phone')?.invalid && createuserform2!.get('phone')?.touched"
              class="text-danger">
              Phone field is required
            </div>
          </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="title">Payment Method Title</label>
              <input type="text" formControlName="payment_method_title" class="form-control" id="payment_method_title"
                placeholder="Enter Payment Method Title"  />
              <div
                *ngIf="createuserform2!.get('payment_method_title')?.invalid && createuserform2!.get('payment_method_title')?.touched"
                class="text-danger">
                Payment Method Title field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="shipping_charge">Shipping Charge</label>
              <input type="text" formControlName="shipping_charge" class="form-control" id="shipping_charge"
                placeholder="Enter Shipping Charge"  />
              <div
                *ngIf="createuserform2!.get('shipping_charge')?.invalid && createuserform2!.get('shipping_charge')?.touched"
                class="text-danger">
                Shipping Charge field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="code">Payment Method</label>
              <input type="text" formControlName="payment_method" class="form-control" id="payment_method"
                placeholder="Enter Payment Method"  />
              <div
                *ngIf="createuserform2!.get('payment_method')?.invalid && createuserform2!.get('payment_method')?.touched"
                class="text-danger">
                Payment Method field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="code">Set Paid</label>
              <input type="text" formControlName="set_paid" class="form-control" id="set_paid"
                placeholder="Enter Set Paid"  />
              <div
                *ngIf="createuserform2!.get('set_paid')?.invalid && createuserform2!.get('set_paid')?.touched"
                class="text-danger">
                Set Paid field is required
              </div>
            </div>
         
          </div>
          <div class="row mt-2">
            <div  formArrayName="line_items" id="containerElement">
                <div  *ngIf="getItemFormArray1() as itemFormArray">
                    <div *ngFor="let chlid of itemFormArray.controls;let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label for="product">Select Product Name</label>
                            <select class="form-select" aria-label="Default select example" formControlName="product"
                             >
                             <option value="" selected disabled>Select Product Name</option>
                              <option value="Select Product Name" disabled>Select Product Name</option>
                              <option *ngFor="let name of fullData22" [value]="name.product_name">
                                {{ name.product_name }}
                              </option>
                            </select>
                            <div *ngIf="createuserform2!.get('product')?.invalid && createuserform2!.get('product')?.touched"
                              class="text-danger">
                              Product field is required
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <label for="code">Quantity</label>
                            <input type="text" formControlName="quantity" class="form-control" id="quantity"
                              placeholder="Enter Quantity"  />
                          </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <img src="assets/images/minus.png" width="24px" height="24px" *ngIf="i > 0" (click)="removeItem1(i)" class="mt-1">
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-3">
                       <img src="assets/images/add.png" width="24px" height="24px" class="mt-2" (click)="addItem1()">
                </div>
            </div>
        </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="create_order1()">Submit</button>
    </div>

  </div>
</ng-template>

<ng-template #updateorder let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Update Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform4">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="name">Name</label>
              <input type="text" formControlName="name" class="form-control" id="name" placeholder="Enter Name"  [(ngModel)]="name11"
              />
              <div *ngIf="createuserform4!.get('name')?.invalid && createuserform4!.get('name')?.touched"
                class="text-danger">
                Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Email</label>
              <input type="text" formControlName="email_id" class="form-control" id="email" [(ngModel)]="email_id11"
                placeholder="Enter Email"  />
              <div
                *ngIf="createuserform4!.get('email_id')?.invalid && createuserform4!.get('email_id')?.touched"
                class="text-danger">
                Email field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="address1">Address</label>
              <input type="text" formControlName="address" class="form-control" id="address" [(ngModel)]="address11"
                placeholder="Enter Address"  />
              <div
                *ngIf="createuserform4!.get('address')?.invalid && createuserform4!.get('address')?.touched"
                class="text-danger">
                Address field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="pincode">Pincode</label>
              <input type="text" formControlName="pincode" class="form-control" id="pincode"  maxlength="6" minlength="6" (keypress)="numericOnly($event)" [(ngModel)]="pincode11"
                placeholder="Enter Pincode"  />
              <div
                *ngIf="createuserform4!.get('pincode')?.invalid && createuserform4!.get('pincode')?.touched"
                class="text-danger">
                Pincode field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
          <div class="col-md-6 mb-3">
            <label for="phone">Phone</label>
            <input type="text" formControlName="phone" class="form-control" id="phone"  maxlength="10" minlength="10" (keypress)="numericOnly($event)" [(ngModel)]="phone11"
              placeholder="Enter Phone" />
            <div
              *ngIf="createuserform4!.get('phone')?.invalid && createuserform4!.get('phone')?.touched"
              class="text-danger">
              Phone field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="title">Awb</label>
            <input type="text" formControlName="awb" class="form-control" id="awb" [(ngModel)]="awb11"
              placeholder="Enter Awb"  />
            <div
              *ngIf="createuserform4!.get('awb')?.invalid && createuserform4!.get('awb')?.touched"
              class="text-danger">
              Awb field is required
            </div>
          </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="title">Location Latitude</label>
              <input type="text" formControlName="location_latitude" class="form-control" id="location_latitude"
                placeholder="Enter Location Latitude"  />
              <div
                *ngIf="createuserform4!.get('location_latitude')?.invalid && createuserform4!.get('location_latitude')?.touched"
                class="text-danger">
                Location Latitude field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="shipping_charge">Location Longitude</label>
              <input type="text" formControlName="location_longitude" class="form-control" id="location_longitude"
                placeholder="Enter Location Longitud"  />
              <div
                *ngIf="createuserform4!.get('location_longitude')?.invalid && createuserform4!.get('location_longitude')?.touched"
                class="text-danger">
                Location Longitud field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-12 mb-3">
              <label for="title">Status</label>
              <input type="text" formControlName="status" class="form-control"  [(ngModel)]="status11"
                placeholder="Enter status"  />
              <div
                *ngIf="createuserform4!.get('status')?.invalid && createuserform4!.get('status')?.touched"
                class="text-danger">
                Status field is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="update_order()"    [disabled]="createuserform4.invalid" >Submit</button>
    </div>

  </div>
</ng-template>


<ng-template #partialrefund let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Partial Refuns</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform8">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="name">Order Id</label>
              <input type="text" formControlName="order_id" class="form-control" id="order_id" placeholder="Enter Order Id"
              />
              <div *ngIf="createuserform8!.get('order_id')?.invalid && createuserform8!.get('order_id')?.touched"
                class="text-danger">
                Order Id field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Reason</label>
              <input type="text" formControlName="reason" class="form-control" id="reason" 
                placeholder="Enter Reason"  />
              <div
                *ngIf="createuserform8!.get('reason')?.invalid && createuserform8!.get('reason')?.touched"
                class="text-danger">
                Reason field is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="partial_order()"    [disabled]="createuserform8.invalid" >Submit</button>
    </div>

  </div>
</ng-template>
<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>