import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatDialogModule } from '@angular/material/dialog';


import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ChatComponent } from './chat/chat.component';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { KanbanComponent } from './kanban/kanban.component';
import { EmailModule } from './email/email.module';
import { UIModule } from './ui/ui.module';
import { IconsModule } from './icons/icons.module';
import { ChartModule } from './chart/chart.module';
import { FormModule } from './form/form.module';
import { TablesModule } from './tables/tables.module';
import { MapsModule } from './maps/maps.module';
import { ProfileComponent } from './profile/profile.component';
import { CreateuserComponent } from './createuser/createuser.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditComponent } from './edit/edit.component';
// import { SharedModule } from '../shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { OrdersComponent } from './orders/orders.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DetailsComponent } from './details/details.component';
import { OpenComponent } from './open/open.component';
import { SuperuserComponent } from './superuser/superuser.component';
import { SharedModule } from '../shared/shared.module';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { TicketsComponent } from './tickets/tickets.component';
import { CancelComponent } from './cancel/cancel.component';
import { UpdateComponent } from './update/update.component';
import { SupportComponent } from './support/support.component';
import { TicketviewComponent } from './ticketview/ticketview.component';
import { SupportticketComponent } from './supportticket/supportticket.component';
import { RefundComponent } from './refund/refund.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { RepeatedComponent } from './repeated/repeated.component';
import { MatIconModule } from '@angular/material/icon';
import { ScannerComponent } from './scanner/scanner.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { UsersListComponent } from './users-list/users-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartialOrdersComponent } from './partial-orders/partial-orders.component';
import { DisputeViewComponent } from './dispute-view/dispute-view.component';
import { AlternativeOrdersComponent } from './alternative-orders/alternative-orders.component';


// import { MatSelectModule } from '@angular/material/select';

// import { MatSortModule } from '@angular/material/sort';





// import { MatDatepickerRangeModule } from '@angular/material/datepicker';
// ... other imports

// @NgModule({
//   declarations: [
    // ... your components
  
    // DetailsComponent,
    // OpenComponent,
    // SuperuserComponent
  // ],
  // imports: [
    // MatPaginatorModule,
    // SharedModule,


 
    // ... other modules
//   ],
// })
// export class YourModule { }




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, CalendarComponent, ChatComponent, KanbanComponent, ProfileComponent, CreateuserComponent, EditComponent, OrdersComponent,  DetailsComponent,
    OpenComponent,
    SuperuserComponent,
    TicketsComponent,
    CancelComponent,
    UpdateComponent,
    SupportComponent,
    TicketviewComponent,
    SupportticketComponent,
    RefundComponent,
    RepeatedComponent,
    ScannerComponent,
    WhatsappComponent,
    PermissionsComponent,
    UsersListComponent,
    PartialOrdersComponent,
    DisputeViewComponent,
    AlternativeOrdersComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    UIModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgApexchartsModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    EcommerceModule, EmailModule,
    IconsModule,
    ChartModule,
    FormModule,
    TablesModule,
    MapsModule,
    LeafletModule,
    WidgetModule,
    MatDialogModule,
    SharedModule,
    NgxEchartsModule,
    MatTableModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    NgSelectModule,
    MatIconModule
    

    
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule { }
