<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/Infotech.png" alt="" height="22">
          </span>
          <span class="logo-lg  mt-5">
            <img src="assets/images/blogo.png" alt="" height="40" style="margin-top: 20px!important;">
            <!-- <img src="assets/images/wlogo.svg" alt="" height="20"> -->
          </span>
        </a>

        <a routerLink="/page/retailripple" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/Infotech.png" alt="" height="22">
          </span>
          <span class="logo-lg mt-5">
            <img src="assets/images/font.png" alt="" height="40" style="margin-top: 20px!important;">

            <!-- <img src="asstes/images/wlogo.svg" alt="" height="20"> -->
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect hello" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>



      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="ri-search-line"></span>
        </div>
      </form> -->

      <!-- <div class="dropdown dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        <button ngbDropdownToggle type="button" class="btn header-item waves-effect" data-toggle="dropdown"
          aria-haspopup="false" aria-expanded="false">
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
    </div>

    <div class="d-flex">

      <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div> -->

      <div class="d-flex justify-content-between laptop" style="margin-top: 24px;">
        <!-- <div class="d-flex">
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedOption">
              <mat-option value="order_id" (click)="setplaceholder('Search For Order id')">Order Id</mat-option>
              <mat-option value="awb" (click)="setplaceholder('Search For Awb')">Awb</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="ms-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>{{placeholder_name}}</mat-label>
              <input matInput placeholder="" value="" [(ngModel)]="searchTerm"
                (keyup)="applyFilter($event.target.value)">
            </mat-form-field>
          </div>
        </div> -->
        <div class="d-flex">
          <select [(ngModel)]="selectedOption" (change)="onSelectChange($event)" id="select" class="form-select">
            <option value="order_id">Order Id</option>
            <option value="awb">Awb</option>
            <option value="phone">Phone Number</option>
          </select>
          <div class="ms-2">
            <!-- <input placeholder="{{placeholder_name}}" value="" [(ngModel)]="searchTerm" (keyup)="applyFilter($event.target.value)" id="input" class="form-control"> -->
            <input [placeholder]="placeholderText" value="" [(ngModel)]="searchTerm" (keyup.enter)="applyFilter($event.target.value)" id="input" class="form-control">

          </div>
        </div>
        

      </div>

      <ng-template #content let-modal>
        <div class="modal-header">
          <div class="modal-title">
            <h4 class="mt-3" style="font-size: 30px;">Create User</h4>
            <p class="mt-2">All Fileds are Mandatory</p>
            <div *ngIf="superuser === 'false'" style="color: red;">
              <p *ngIf="count >= '3'">You cannot create more than 3 accounts</p>
            </div>
          </div>

          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="createuserform" *ngIf="superuser === 'true'">
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="firstname">First Name</label>
                <input type="text" formControlName="first_name" class="form-control" id="firstname"
                  placeholder="First Name" (blur)="only_spl_and_num_not_accept($event,'First Name')" />
                <div *ngIf="createuserform!.get('first_name')?.invalid && createuserform!.get('first_name')?.touched"
                  class="text-danger">
                  First Name field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="lastname">Last Name</label>
                <input type="text" formControlName="last_name" class="form-control" id="lastname"
                  placeholder="Last Name" (blur)="only_spl_and_num_not_accept($event,'Last Name')" />
                <div *ngIf="createuserform!.get('last_name')?.invalid && createuserform!.get('last_name')?.touched"
                  class="text-danger">
                  Last Name field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="email">Email</label>
                <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email"  />
                <div class="text-danger"
                  *ngIf="createuserform.get('email').invalid && (createuserform.get('email').dirty || createuserform.get('email').touched)">
                  Please enter a valid email address.
                </div>
              </div>
              <div class="col-md-6">
                <label for="phonenumber">Phone Number</label>
                <input type="phone number" formControlName="phone_number" class="form-control" id="phone_number"
                  placeholder="Phone Number" maxlength="10" minlength="10" (keypress)="numericOnly($event)" />
                <div
                  *ngIf="createuserform!.get('phone_number')?.invalid && createuserform!.get('phone_number')?.touched"
                  class="text-danger">
                  Phone Number must contain 10 digits
                </div>
              </div>

            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="form-control" id="username"
                  placeholder="User Name" />
                <div *ngIf="createuserform!.get('username')?.invalid && createuserform!.get('username')?.touched"
                  class="text-danger">
                  Username field is required
                </div>
              </div>
              <!-- <div class="col-md-6">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" id="password" placeholder="Password" />
                    <div *ngIf="createuserform!.get('password')?.invalid && createuserform!.get('password')?.touched" class="text-danger" >
                      Password field is required 
                    </div> 
                </div> -->
              <div class="col-md-6">
                <label for="password">Password</label>
                <div class="password-input">
                  <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control"
                    id="password" placeholder="Password" />
                  <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()">
                    <i class="fa" style="color: black;"
                      [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"></i>
                  </button>
                </div>
                <div *ngIf="createuserform!.get('password')?.invalid && createuserform!.get('password')?.touched"
                  class="text-danger">
                  Password field is required
                </div>
              </div>



            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="isactive">Is Active</label>
                <select formControlName="is_active" class="form-select" id="is_active">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <div *ngIf="createuserform!.get('is_active')?.invalid && createuserform!.get('is_active')?.touched"
                  class="text-danger">
                  Is Active field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="issuperuser">Is SuperUser</label>
                <select formControlName="is_superuser" class="form-select" id="is_superuser">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <div
                  *ngIf="createuserform!.get('is_superuser')?.invalid && createuserform!.get('is_superuser')?.touched"
                  class="text-danger">
                  Is SuperUser field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="portalname">Portal Name</label>
                <!-- <input type="text" formControlName="portal_name" class="form-control" id="portalname" placeholder="Portal Name" /> -->
                <select class="form-select" aria-label="Default select example" formControlName="portal_name"
                  [(ngModel)]="selectedPortalName" (change)="onPortalNameChange()">
                  <option value="Select Portal Name" disabled>Select Portal Name</option>
                  <option *ngFor="let name of fullData" [value]="name.portal_name">
                    {{ name.portal_name }}
                  </option>
                </select>
                <!-- <select class="form-select" aria-label="Default select example"   formControlName="portal_name">
                    <option value="null">Select Portal Name</option>
                    <option *ngFor="let name of fullData" [value]="name.portal_account_id">
                      {{ name.portal_name }}
                    </option>
                </select> -->
                <div *ngIf="createuserform!.get('portal_name')?.invalid && createuserform!.get('portal_name')?.touched"
                  class="text-danger">
                  Portal Name field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="portal_id">Portal Id</label>
                <input type="text" formControlName="portal_id" class="form-control" id="portal_id"
                  placeholder="Portal Id" [(ngModel)]="selectedportal" readonly />
                <div *ngIf="createuserform!.get('portal_id')?.invalid && createuserform!.get('portal_id')?.touched"
                  class="text-danger">
                  Portal Id field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="portalaccountid">Portal Account Id</label>
                <input type="text" formControlName="portal_account_id" class="form-control" id="portal_account_id"
                  placeholder="Portal Account Id" [(ngModel)]="selectedPortalId" readonly />
                <div
                  *ngIf="createuserform!.get('portal_account_id')?.invalid && createuserform!.get('portal_account_id')?.touched"
                  class="text-danger">
                  Portal Account Id field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="d2cname">D2C Name</label>
                <input type="text" formControlName="d2c_name" class="form-control" id="d2c_name"
                  placeholder="D2C Name" (blur)="only_spl_and_num_not_accept($event,'D2C Name')" />
                <div *ngIf="createuserform!.get('d2c_name')?.invalid && createuserform!.get('d2c_name')?.touched"
                  class="text-danger">
                  D2C Name field is required
                </div>
              </div>
            </div>

          </form>
          <form [formGroup]="createuserform" *ngIf="superuser === 'false'">
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="firstname">First Name</label>
                <input type="text" formControlName="first_name" class="form-control" id="firstname" (blur)="only_spl_and_num_not_accept($event,'First Name')"
                  placeholder="First Name" />
                <div *ngIf="createuserform!.get('first_name')?.invalid && createuserform!.get('first_name')?.touched"
                  class="text-danger">
                  First Name field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="lastname">Last Name</label>
                <input type="text" formControlName="last_name" class="form-control" id="lastname"
                  placeholder="Last Name" (blur)="only_spl_and_num_not_accept($event,'Last Name')" />
                <div *ngIf="createuserform!.get('last_name')?.invalid && createuserform!.get('last_name')?.touched"
                  class="text-danger">
                  Last Name field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="email">Email</label>
                <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email" />
                <div class="text-danger"
                  *ngIf="createuserform.get('email').invalid && (createuserform.get('email').dirty || createuserform.get('email').touched)">
                  Please enter a valid email address.
                </div>
              </div>
              <div class="col-md-6">
                <label for="phonenumber">Phone Number</label>
                <input type="phone number" formControlName="phone_number" class="form-control" id="phone_number"
                  placeholder="Phone Number" maxlength="10" minlength="10" (keypress)="numericOnly($event)" />
                <div
                  *ngIf="createuserform!.get('phone_number')?.invalid && createuserform!.get('phone_number')?.touched"
                  class="text-danger">
                  Phone Number must contain 10 digits
                </div>
              </div>

            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="form-control" id="username"
                  placeholder="User Name" />
                <div *ngIf="createuserform!.get('username')?.invalid && createuserform!.get('username')?.touched"
                  class="text-danger">
                  Username field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control" id="password"
                  placeholder="Password" />
                <div *ngIf="createuserform!.get('password')?.invalid && createuserform!.get('password')?.touched"
                  class="text-danger">
                  Password field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6" style="display: none;">
                <label for="isactive">Is Active</label>
                <input type="text" formControlName="is_active" class="form-control" id="is_active"
                  placeholder="Is Active" readonly (focus)="showToastrMessage('is_active')" />
                <div *ngIf="createuserform!.get('is_active')?.invalid && createuserform!.get('is_active')?.touched"
                  class="text-danger">
                  Is Active field is required
                </div>
              </div>
              <div class="col-md-6" style="display: none;">
                <label for="issuperuser">Is SuperUser</label>
                <input type="text" formControlName="is_superuser" class="form-control" id="is_superuser"
                  placeholder="Is SuperUser" readonly (focus)="showToastrMessage('is_superuser')" />
                <div
                  *ngIf="createuserform!.get('is_superuser')?.invalid && createuserform!.get('is_superuser')?.touched"
                  class="text-danger">
                  Is SuperUser field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <label for="portalname">Portal Name</label>
                <!-- <input type="text" formControlName="portal_name" class="form-control" id="portalname" placeholder="Portal Name" /> -->
                <input type="text" formControlName="portal_name" class="form-control" id="portal_name"
                  placeholder="Portal Name" [(ngModel)]="portalname" (focus)="showToastrMessage('portal_name')"
                  readonly />
                <!-- <select class="form-select" aria-label="Default select example" formControlName="portal_name"  [(ngModel)]="selectedPortalName" (change)="onPortalNameChange()">
                  <option value="Select Portal Name"  disabled>Select Portal Name</option>
                  <option *ngFor="let name of fullData" [value]="name.portal_name"  >
                    {{ name.portal_name }}
                  </option> -->
                <!-- </select> -->
                <!-- <select class="form-select" aria-label="Default select example"   formControlName="portal_name">
                  <option value="null">Select Portal Name</option>
                  <option *ngFor="let name of fullData" [value]="name.portal_account_id">
                    {{ name.portal_name }}
                  </option>
              </select> -->
                <div *ngIf="createuserform!.get('portal_name')?.invalid && createuserform!.get('portal_name')?.touched"
                  class="text-danger">
                  Portal Name field is required
                </div>
              </div>
              <div class="col-md-6">
                <label for="d2cname">Platform Name</label>
                <input type="text" formControlName="d2c_name" class="form-control" id="d2c_name" placeholder="Platform Name"
                  [(ngModel)]="d2cname" readonly (focus)="showToastrMessage('d2c_name')" />
                <div *ngIf="createuserform!.get('d2c_name')?.invalid && createuserform!.get('d2c_name')?.touched"
                  class="text-danger">
                  Platform Name field is required
                </div>
              </div>
              <div class="col-md-6" style="display: none;">
                <label for="portal_id">Portal Id</label>
                <input type="text" formControlName="portal_id" class="form-control" id="portal_id"
                  placeholder="Portal Id" readonly [(ngModel)]="portalid" (focus)="showToastrMessage('portal_id')" />
                <div *ngIf="createuserform!.get('portal_id')?.invalid && createuserform!.get('portal_id')?.touched"
                  class="text-danger">
                  Portal Id field is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6" style="display: none;">
                <label for="portalaccountid">Portal Account Id</label>
                <input type="text" formControlName="portal_account_id" class="form-control" id="portal_account_id"
                  placeholder="Portal Account Id" [(ngModel)]="portalaccountid" readonly
                  (focus)="showToastrMessage('portal_account_id')" />
                <div
                  *ngIf="createuserform!.get('portal_account_id')?.invalid && createuserform!.get('portal_account_id')?.touched"
                  class="text-danger">
                  Portal Account Id field is required
                </div>
              </div>
              <!-- <div class="col-md-6">
                <label for="d2cname">Platform Name</label>
                <input type="text" formControlName="d2c_name" class="form-control" id="d2c_name" placeholder="Platform Name"
                  [(ngModel)]="d2cname" readonly (focus)="showToastrMessage('d2c_name')" />
                <div *ngIf="createuserform!.get('d2c_name')?.invalid && createuserform!.get('d2c_name')?.touched"
                  class="text-danger">
                  Platform Name field is required
                </div>
              </div> -->
            </div>

          </form>
        </div>
        <div class="modal-footer d-flex">
          <div *ngIf="superuser === 'false' && count < '3'">
            <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" (click)="onSubmit()"
              [disabled]="!createuserform.valid">Submit</button>
          </div>
          <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" (click)="onSubmit()"
            [disabled]="!createuserform.valid" *ngIf="superuser === 'true'">Submit</button>
          <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
        </div>
        <ng-container *ngIf="showOverlay" id="overlay-container">
          <app-spinnerr [overlay]="true" id="loader"></app-spinnerr>
        </ng-container>
      </ng-template>

      <!-- <button class="btn btn-outline-primary mb-2 me-2" >
        Modal vertically centered
      </button> -->
      <!-- Add *ngIf to show only one icon based on the dark mode state -->
      <!-- <div>
        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
          <i class='bx bx-moon fs-22' *ngIf="!isDarkModeOn" (click)="onDarkModeChange()"></i>
          <i class='bx bx-sun fs-22' *ngIf="isDarkMode" (click)="onLightModeChange()"></i>
        </button>
      </div> -->




      <div class="dropdown  d-lg-inline-block ms-1 mt-2" ngbDropdown #deviceDrop="ngbDropdown">
        <button type="button" id="dropdownBasic1" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
         (mouseenter)="deviceDrop.open()">
          <i class="ri-apps-2-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end"  ngbDropdownMenu aria-labelledby="dropdownBasic1" id="dropdown11" (mouseleave)="$event.stopPropagation(); deviceDrop.close();">
          <div class="px-lg-4">
            <div class="row g-0">
              <div class="col" *ngIf="superuser === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="openVerticallyCentered(content); deviceDrop.close()">
                  <img src="assets/images/user22.png" alt="Github" width="24px" height="24px">
                  <span>Create User</span>
                </a>
              </div>
              <div class="col" *ngIf="superuser === 'false'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="openVerticallyCentered(content); deviceDrop.close()">
                  <img src="assets/images/user22.png" alt="Github" width="24px" height="24px">
                  <span>Create Subuser</span>
                </a>
              </div>
              <div class="col" *ngIf="superuser === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" routerLink="/page/update" (click)="deviceDrop.close()">
                  <img src="assets/images/portal22.png" alt="bitbucket" width="24px" height="24px">
                  <span>Update Portal</span>
                </a>
              </div>
             
              <!-- <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="dropbox">
                  <span>Support Ticket</span>
                </a>
              </div> -->
              <!-- <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div> -->
            </div>

            <div class="row g-0">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="openVerticallyCentered1(content3) ;deviceDrop.close()">
                  <img src="assets/images/support22.png" alt="dropbox" width="20px" height="20px">
                  <span>Support Ticket</span>
                </a>
              </div>
              <div class="col" *ngIf="superuser === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="openVerticallyCentered2(content4); deviceDrop.close()">
                  <img src="assets/images/approval.png" alt="bitbucket" width="24px" height="24px">
                  <span>Create Permission</span>
                </a>
              </div>
              <div class="col" *ngIf="is_whatsapp === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="openVerticallyCentered3(content5); deviceDrop.close()">
                  <img src="assets/images/create.png" alt="bitbucket" width="24px" height="24px">
                  <span>WhatsApp Template Create Request</span>
                </a>
              </div>
              <!-- <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div> -->
            </div>
            <div class="row g-0">
              <div class="col" *ngIf="superuser === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="route111() ;deviceDrop.close()">
                  <img src="assets/images/p&l.png" alt="dropbox" width="20px" height="20px">
                  <span>Generate Post P&L</span>
                </a>
              </div>
              <div class="col" *ngIf="superuser === 'true'">
                <a class="dropdown-icon-item" href="javascript: void(0);" (click)="route11(); deviceDrop.close()">
                  <img src="assets/images/weight.png" alt="bitbucket" width="24px" height="24px">
                  <span>Weight Dispute</span>
                </a>
              </div>
              <!-- <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <button type="button"  (click)="onDarkModeChange()" *ngIf="isLightModeOn">
        Dark Mode
      </button> -->
      <img src="assets/images/moon.png" width="30px" height="30px" *ngIf="isLightModeOn" (click)="onDarkModeChange()"
        style="margin-top: 27px!important; cursor: pointer;" class="mt-3">
      <img src="assets/images/sun1.png" width="30px" height="30px" *ngIf="isDarkModeOn" (click)="onLightModeChange()"
        style="margin-top: 27px!important; cursor: pointer;" class="mt-3">


      <!-- <button type="button"  (click)="onLightModeChange()" *ngIf="!isLightModeOn">
        Light Mode
      </button> -->

      <div class="dropdown d-none d-lg-inline-block ms-1 mt-2">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block mt-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-notification-3-line"></i>
          <span class="noti-dot"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="flex-1">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="flex-1">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="flex-1">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="flex-1">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block user-dropdown mt-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="{{profile_Service.imageURL}}" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{profile_Service.first_name}}</span>
          <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" routerLink="/page/retailripple/profile"><i
              class="ri-user-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"  routerLink="/page/retailripple/permissions"><i class="ri-user-line align-middle me-1"></i>
              Permissions</a> -->
              <a class="dropdown-item" href="javascript: void(0);" routerLink="/page/retailripple/permissions" *ngIf="superuser === 'true'">
                <i class="fa-solid fa-user-shield align-middle me-1"></i>
                Permissions
              </a> 
              <a class="dropdown-item" href="javascript: void(0);" routerLink="/page/retailripple/users-list" *ngIf="superuser === 'true'">
                <i class="fa-solid fa-users  align-middle me-1"></i>
                Users List
              </a>                
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i
              class="ri-wallet-2-line align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-end mt-1">11</span><i
              class="ri-settings-2-line align-middle me-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a> -->
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="ri-lock-unlock-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div> -->

    </div>
  </div>
  <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
    Launch demo modal
  </button> -->

  <!-- Modal -->
  <ng-template #content1 let-modal>
    <div class="modal-header">
      <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body" (click)="route()">
      <div class="card p-3" style="box-shadow: 1px 1px 1px 1px grey">
        <p style="cursor: pointer;" class="d-flex justify-content-center">Order Id:{{this.searchdata[0].order_id}}</p>
        <p style="cursor: pointer;" class="d-flex justify-content-center">Awb:{{this.searchdata[0].awb}}</p>
      </div>

    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
  </ng-template>
  <button #modalButton class="btn btn-lg btn-outline-primary" style="display: none;" (click)="open(content1)">Launch
    demo modal</button>
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body d-flex justify-content-center">
      <p>Result Not Found</p>
      <!-- <p style="cursor: pointer;">Awb:{{this.searchdata[0].awb}}</p> -->
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
  </ng-template>
  <button #modalButton1 class="btn btn-lg btn-outline-primary" style="display: none;" (click)="open1(content2)">Launch
    demo modal</button>
  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Raise Support Ticket</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform1">
        <div class="row col-md-12">
          <div class="col-md-6 mb-3">
            <label for="disposition">Is Order Issue</label>
            <select formControlName="is_order_issue" class="form-select" id="is_order_issue">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'false'">
            <label for="status">Category</label>
            <select formControlName="category" class="form-select" id="category">
              <option value="" disabled selected>Select Category</option>
              <option value="new product listing">New Product Listing</option>
              <option value="product price changes">Product Price Changes</option>
              <option value="payment gateway issues">payment gateway issues</option>
              <option value="website theme or plugin changes">Website Theme or Plugin Changes</option>
              <option value="inventory">Inventory</option>
              <option value="product related">Product Related</option>
              <option value="cancel or return">Cancel or Return</option>
              <option value="notification services">Notification Services</option>
              <option value="invoice issues">Invoice Issues</option>
              <option value="delivery related issues">Delivery Related Issues</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'true'">
            <label for="status">Category</label>
            <select formControlName="category" class="form-select" id="category">
              <option value="" disabled selected>Select Category</option>
              <option value="missing order">Missing Order</option>
              <option value="wrong product dispatched">Wrong Product Dispatched</option>
              <option value="order not received">Order Not Received</option>
              <option value="missing quantity">Missing Quantity</option>
              <option value="wrong invoice shared">Wrong Invoice Shared</option>
              <option value="order delay">Order Delay</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'true'">
            <label for="order_id">Order Id</label>
            <input type="text" formControlName="order_id" class="form-control" id="order_id" placeholder="Order Id" />
            <div *ngIf="createuserform1!.get('order_id')?.invalid && createuserform1!.get('order_id')?.touched"
              class="text-danger">
              Order Id field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'true'">
            <label for="awb">Awb</label>
            <input type="text" formControlName="awb" class="form-control" id="awb" placeholder="Awb" />
            <div *ngIf="createuserform1!.get('awb')?.invalid && createuserform1!.get('awb')?.touched"
              class="text-danger">
              Awb field is required
            </div>
          </div>
          <!-- <div class="col-md-6 mb-3">
            <label for="status">Status</label>
            <select formControlName="status" class="form-select" id="status1">
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select>
          </div>  -->
          <!-- <div class="col-md-6 mb-3">
            <label for="disposition">Disposition</label>
            <select formControlName="disposition" class="form-select" id="disposition">
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="in progress">In Progress</option>
              <option value="resolved">Resolved</option>
            </select>
          </div>  -->

          <div class="col-md-6 mb-3">
            <label for="agent_remark">Agent Remark</label>
            <input type="text" formControlName="agent_remark" class="form-control" id="agent_remark" (blur)="only_spl_and_num_not_accept($event,'Agent Remark')"
              placeholder="Agent Remark" />
            <div *ngIf="createuserform1!.get('agent_remark')?.invalid && createuserform1!.get('agent_remark')?.touched"
              class="text-danger">
              Agent Remark field is required
            </div>
          </div>

          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'true'">
            <label for="voice_of_customer">Voice Of Customer</label>
            <input type="text" formControlName="voice_of_customer" class="form-control" id="voice_of_customer" (blur)="only_spl_and_num_not_accept($event,'Voice Of Customer')"
              placeholder="Voice Of Customer" />
            <div
              *ngIf="createuserform1!.get('voice_of_customer')?.invalid && createuserform1!.get('voice_of_customer')?.touched"
              class="text-danger">
              Voice Of Customer field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'false'">
            <label for="support_chart">Leave A Note</label>
            <input type="text" formControlName="support_chat" class="form-control" id="support_chart" (blur)="only_spl_and_num_not_accept($event,'Leave A Note')"
              placeholder="Leave A Note" />
            <div
              *ngIf="createuserform1!.get('support_chart')?.invalid && createuserform1!.get('support_chart')?.touched"
              class="text-danger">
              Leave A Note field is required
            </div>
          </div>
          <div class="col-md-12 mb-3" *ngIf="createuserform1.get('is_order_issue')?.value === 'true'">
            <label for="support_chart">Leave A Note</label>
            <input type="text" formControlName="support_chat" class="form-control" id="support_chart" (blur)="only_spl_and_num_not_accept($event,'Leave A Note')"
              placeholder="Leave A Note" />
            <div
              *ngIf="createuserform1!.get('support_chart')?.invalid && createuserform1!.get('support_chart')?.touched"
              class="text-danger">
              Leave A Note field is required
            </div>
          </div>


        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
        (click)="support_ticket_create()">Submit</button>
    </div>
  </ng-template>


  <ng-template #content4 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Create Permission</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform4">
        <div class="row col-md-12">
          <div class="col-md-6 mb-3">
            <label for="disposition">Is Whatsapp</label>
            <select formControlName="is_whatsapp" class="form-select"  aria-label="Default select example"  id="is_whatsapp">
              <option value="" selected disabled>Select an option</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Marketing Notifications</label>
            <select formControlName="is_whatsapp_marketing" class="form-select" aria-label="Default select example" id="is_whatsapp_marketing">
              <option value="" selected disabled>Select an option</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Order Notifications</label>
            <select formControlName="order_notifications" class="form-select" aria-label="Default select example" id="order_notifications">
              <option value="" selected disabled>Select an option</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Custom Notifications</label>
            <select formControlName="custom_notifications" class="form-select" aria-label="Default select example" id="custom_notifications">
              <option value="" selected disabled>Select an option</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_id">Credit Amount</label>
            <input type="text" formControlName="credit_amount" class="form-control" id="credit_amount" placeholder="Credit Amount" />
            <div *ngIf="createuserform4!.get('credit_amount')?.invalid && createuserform4!.get('credit_amount')?.touched"
              class="text-danger">
              Credit Amount field is required
            </div>
          </div>
          <div class="col-md-6">
            <label for="portalname">Portal</label>
            <select class="form-select" aria-label="Default select example" formControlName="portal_account_id"
             >
             <option value="" selected disabled>Select Portal</option>
              <option value="Select Portal Name" disabled>Select Portal Name</option>
              <option *ngFor="let name of fullData" [value]="name.portal_account_id">
                {{ name.portal_name }}
              </option>
            </select>
            <div *ngIf="createuserform4!.get('portal_account_id')?.invalid && createuserform4!.get('portal_account_id')?.touched"
              class="text-danger">
              Portal field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_amount">Per Message Order Notification Cost</label>
            <input type="text" formControlName="order_amount" class="form-control" id="order_amount" placeholder="Enter Order Notification Amount" />
            <div *ngIf="createuserform4!.get('order_amount')?.invalid && createuserform4!.get('order_amount')?.touched"
              class="text-danger">
              Order Notification Amount field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_amount">Per Message Marketing Notification Cost</label>
            <input type="text" formControlName="marketing_amount" class="form-control" id="marketing_amount" placeholder="Enter Marketing Notification Amount" />
            <div *ngIf="createuserform4!.get('marketing_amount')?.invalid && createuserform4!.get('marketing_amount')?.touched"
              class="text-danger">
              Marketing Notification Amount field is required
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
        (click)="permission_create()">Create</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinnerr [overlay]="true" id="loader"></app-spinnerr>
    </ng-container>
  </ng-template>
  <ng-template #content5 let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="variableModalLabel">Create Template</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform5">
        <div class="col-md-12 mb-3">
          <label for="type">Select Type</label>
          <select formControlName="type" class="form-select"  aria-label="Default select example"  id="type">
            <option value="" disabled selected>Select Type</option>
            <option value="Order Notifications">Order Notifications</option>
            <option value="Marketing Notifications">Marketing Notifications</option>
            <option value="Custom Notifications">Custom Notifications</option>
          </select>
        </div>
        <div class="col-md-12 mb-3">
          <label for="order_id">Subtype</label>
          <input type="text" formControlName="subtype" class="form-control" id="subtype" placeholder="Subtype"  />
          <div *ngIf="createuserform5!.get('subtype')?.invalid && createuserform5!.get('subtype')?.touched"
            class="text-danger">
            Subtype field is required
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary"  (click)="create_template()">Continue</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinnerr [overlay]="true" id="loader"></app-spinnerr>
    </ng-container>
  </ng-template>
</header>
<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinnerr [overlay]="true" id="loader"></app-spinnerr>
</ng-container>