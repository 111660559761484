import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';
import { FormControl} from '@angular/forms';


import { SharedService } from 'src/app/core/services/shared.service';

import { ChartType, Stat, Chat, Transaction } from './dashboard.model';

import { statData, revenueChart, sparklineEarning, sparklineMonthly, chatData, transactions } from './data';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ordersData } from '../ecommerce/orders/data';
import { ToastrService } from 'ngx-toastr';
import { number } from 'echarts';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter'; 
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },

  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})



/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  chatData: Chat[];
  selectedInterval: 'Yearly' | 'Weekly' | 'Monthly' = 'Weekly';
  transactions: Transaction[];
  statData: Stat[];
  showOverlay: any;
  fullData: any;
  monthly: any
  monthlyData: any;
  monthlyData1: any;
  data: any;
  monthlyValues: any
  yearlyData: any;
  weeklyData: any;
  prepaid_orders_count: any;
  cod_count: any;
  cod_sp: any;
  prepaid_orders_sp: any;
  last_thirty_days_orders_count: any;
  todays_orders_count: any;
  order_status: any
  toporder: any;
  visibleCards = 3;
  showFullView = false;
  selling: any
  selling1: any;
  tickets_valueee:boolean = false
  count1: any;
  d2cname: any
  isCancelled: boolean = false;
  searchdata: any;
  isOptionSelected: boolean = false;
  portal_name: any
  superuser: any
  portal_id: any;
  portal_account_id: any
  d2c_name: any
  // day:any
  day = 30
  content1:any;
  platform_name: any;
  repeated_count:any;
  markers?: any;
  achived:any;
  target:any;
  getvalues:any
  health :any
  jubi :any
  drreddy :any
  puri :any
  beetel :any
  seller:any
  content2:any
  admin1:boolean = false
  selectedOption = 'last_7_days'
  selectedOption1 = 'last_7_days'

  target11 = '3500000'
  // basicColumChart:any








  constructor(
    public formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;
  // splineAreaChart:ChartType;

  // Form submit
  chatSubmit: boolean;

  formData: UntypedFormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Nazox' }, { label: 'Dashboard', active: true }];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this.fetchdata1(this.day);
    this._fetchData();
    this.fetchdata2();
    this.fetch_data(this.selectedInterval);
    // this.account_details();
    this.selling_price();
    this.count();
    this.target_view()
    // this.bulk_status_details()
    this.refund_count_ticket('ticket')
    this.superuser = localStorage.getItem('is_superuser')
    console.log(this.superuser, 'superusersuperuser')
    // this.d2cname = localStorage.getItem('d2c_name')
    // this.portal_name = localStorage.getItem('portal_name2')
    // console.log(this.portal_name,'this.portal_name')
    if (this.superuser === 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
      // console.log(this.portal_name, this.portal_id, this.portal_account_id, 'res.body.portal_account_idres.body.portal_account_id')
      // console.log(this.portal_name, this.portal_account_id, this.portal_id)
    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
      // console.log(this.portal_name,'this.portal_name')
    }
    if (this.d2c_name === 'shopify'){
      this.count22();
    }
    else{
      this.count222();
    }
    console.log(this.d2c_name,'d2c_name for checking')
    // this.selectedOption = 'order_id'; 
    // this.setupPageRefresh();
  }
  private _fetchData() {
    this.revenueChart = revenueChart;
    // this.salesAnalytics = this.salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.transactions = transactions;
    this.statData = statData;

    // this.splineAreaChart = splineAreaChart;
    // this.donughnutChart = this.donughnutChart
    // setTimeout(() => {
    //   this.donughnutChart = this.donughnutChart

    // }, 5000);
  }
  revlon:any
  rusan:any;
  loreal:any

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  refund_count_ticket(value:any){
    this.showOverlay = true;
    this.content2 = value
     if (value == 'ticket'){
      this.tickets_valueee = true
      console.log('bhargavi',this.tickets_valueee)
     }
     else{
      this.tickets_valueee = false
     }
    let data = {
      'get_data':value
    }

    this.authService.refund_count_ticket(data).subscribe((res: any) => {
      let mes = JSON.parse(res);
      if (mes.statusCode === 200) {
        console.log(mes)
        this.showOverlay = false;
        this.getvalues = mes.body
        this.health = this.getvalues.honherhealth
        this.drreddy = this.getvalues.drreddy
        this.puri = this.getvalues.purifry
        this.seller = this.getvalues.sellerbuymore
        this.revlon = this.getvalues.revlon
        this.rusan = this.getvalues.rusan
        this.loreal = this.getvalues.Loreal

      
      }
      else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/auth/login'])
        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
         
        }
     



      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
      } else {
      }
    });

  }

  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: 'right',
        name: 'Ricky Clark',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes()
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });
    }

    this.chatSubmit = true;
  }
  // donughnutChart: ChartType = {
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: "{a} <br/>{b}: {c} ({d}%)"
  //   },
  //   legend: {
  //     orient: 'vertical',
  //     x: 'left',
  //     data: [], // Initialize legend data as empty
  //     textStyle: { color: '#8791af' }
  //   },
  //   colors: ['#556ee6', '#f1b44c', '#f46a6a', '#50a5f1', '#34c38f'],
  //   series: [
  //     {
  //       name: 'Total sales',
  //       type: 'pie',
  //       radius: ['50%', '70%'],
  //       avoidLabelOverlap: false,
  //       label: {
  //         show: false,
  //         position: 'center'
  //       },
  //       emphasis: {
  //         label: {
  //           show: true,
  //           textStyle: {
  //             fontSize: '30',
  //             fontWeight: 'bold'
  //           }
  //         }
  //       },
  //       labelLine: {
  //         show: false
  //       },
  //       data: [] // Initialize series data as empty
  //     }
  //   ]
  // };
  updateChart(interval: 'Yearly' | 'Weekly' | 'Monthly') {

    this.selectedInterval = interval;
    if (interval === 'Yearly') {
      const yearlyData = this.yearlyData[0];
      const years = Object.keys(yearlyData);
      const orderCounts = years.map(year => yearlyData[year].order_count);
      const totalSellingPrices = years.map(year => yearlyData[year].total_selling_price);

      this.basicColumChart.xaxis.categories = years;
      this.basicColumChart.series = [
        {
          name: 'Order Count',
          data: orderCounts.map((count, index) => ({
            x: years[index],  // Use year as x-axis value
            y: count
          })),
        },
        {
          name: 'Selling Price',
          data: totalSellingPrices.map((price, index) => ({
            x: years[index],  // Use year as x-axis value
            y: price
          })),
        },
      ];

    } else if (interval === 'Weekly') {
      const weeksData = this.weeklyData[0];
      const weeks = Object.keys(weeksData);
      const orderCounts = weeks.map(week => weeksData[week].order_count);
      const totalSellingPrices = weeks.map(week => weeksData[week].total_selling_price);

      this.basicColumChart.xaxis.categories = weeks;
      this.basicColumChart.series = [
        {
          name: 'Order Count',
          data: orderCounts.map((count, index) => ({
            x: weeks[index],  // Use week as x-axis value
            y: count
          })),
        },
        {
          name: 'Selling Price',
          data: totalSellingPrices.map((price, index) => ({
            x: weeks[index],  // Use week as x-axis value
            y: price
          })),
        },
      ];
    }
    if (interval === 'Monthly') {
      const monthsData = this.monthlyData[0];
      const months = Object.keys(monthsData);
      const orderCounts = months.map(month => monthsData[month].order_count);
      const totalSellingPrices = months.map(month => monthsData[month].total_selling_price);

      this.basicColumChart.xaxis.categories = months;
      this.basicColumChart.series = [
        {
          name: 'Order Count',
          data: orderCounts.map((count, index) => ({
            x: months[index],
            y: count
          })),
        },
        {
          name: 'Selling Price',
          data: totalSellingPrices.map((price, index) => ({
            x: months[index],
            y: price
          })),
        },
      ];
      // yaxis: {
      //   title: {
      //     text: '$ (thousands)'
      //   }
      // },


      // this.revenueChart.labels = months;
      // this.revenueChart.series = [
      //   {
      //     name: 'Order Count',
      //     type:'column',
      //     data: orderCounts,
      //   },
      //   {
      //     name: 'Total Selling Price',
      //     type:'line',
      //     data: totalSellingPrices,
      //   }
      // ];
    }
  }
  fetch_data(interval: any) {
    this.superuser = localStorage.getItem('is_superuser')
    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
      console.log(this.portal_name, this.portal_id, this.portal_account_id, 'res.body.portal_account_idres.body.portal_account_id')


    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_id': this.portal_id,
      'portal_account_id': this.portal_account_id,
      'filter': interval
    };

    this.showOverlay = true;
    this.authService.order_details(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);

      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.monthlyData = mes.body.Monthly;
        this.weeklyData = mes.body.Weekly;
        this.yearlyData = mes.body.Yearly
        // console.log(this.monthlyData, 'monthlyData');
        // console.log(this.weeklyData, 'Data');
        // console.log(this.yearlyData, 'yearly');
        this.updateChart(interval);
        const monthsData = this.monthlyData[0];
        const months = Object.keys(monthsData);
        // console.log(months, 'months')
        const orderCounts = months.map(month => monthsData[month].order_count);
        // console.log(orderCounts, 'ordercounts')
        const totalSellingPrices = months.map(month => monthsData[month].total_selling_price);
        // console.log(totalSellingPrices, 'totalSellingPrices')
        // Process monthlyData as needed
      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          // this.toastr.error(res.error, "");
          this.showOverlay = false
          this.router.navigate(['/auth/login'])
        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          //   this.isCancelled = true;
          // this.selectedOption = ''
          // this.searchTerm = '';
          // console.log('error mesg',res)
        }
        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    },
    (error) => {
      this.showOverlay = false;
      // if (error.status === 502) {
      // } else {
      // }
    });
  }
  activeButton: string = 'ticket'; // Default active button is 'ticket'

  setActiveButton(button: string): void {
    this.activeButton = button;
    // Call refund_count_ticket function here passing button value if needed
    this.refund_count_ticket(button);
  }
  fetchdata1(content:any) {
    this.content1 = content
    this.superuser = localStorage.getItem('is_superuser')
    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
      // console.log(this.portal_name, this.portal_id, this.portal_account_id, 'res.body.portal_account_idres.body.portal_account_id')


    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_id': this.portal_id,
      'portal_account_id': this.portal_account_id,
      'days':content
      // 'filter': 'monthly'
    };

    this.showOverlay = true;
    this.authService.order_details1(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);

      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.monthlyData1 = mes.body;
        // console.log(this.monthlyData1, 'monthlyData');
        this.cod_count = this.monthlyData1.cod_count
        this.cod_sp = this.monthlyData1.cod_sp
        this.prepaid_orders_count = this.monthlyData1.prepaid_orders_count
        this.prepaid_orders_sp = this.monthlyData1.prepaid_orders_sp
        this.last_thirty_days_orders_count = this.monthlyData1.last_thirty_days_orders_count
        this.todays_orders_count = this.monthlyData1.todays_orders_count
        this.order_status = this.monthlyData1.order_status
        // console.log(this.order_status, 'order_staus')
        // this.donughnutChart.legend.data = this.order_status.map(entry => Object.keys(entry)[0]);
        // this.donughnutChart.series[0].data = this.order_status.map(entry => ({ value: Object.values(entry)[0], name: Object.keys(entry)[0] }));
        // console.log( this.donughnutChart.series[0].data,'this.donughnutChart.series[0].data')
        // const legendData = this.order_status.map(entry => Object.keys(entry)[0]);
        // const seriesData = this.order_status.map(entry => ({ value: Object.values(entry)[0], name: Object.keys(entry)[0] }));
        if (this.order_status && this.order_status.length > 0) {
          this.createSalesAnalyticsChart(this.order_status);
          if (this.salesAnalytics && this.salesAnalytics.labels) {
            // console.log(this.salesAnalytics.labels);
          } else {
            // console.error('this.salesAnalytics or labels is undefined');
          }
        } else {
          if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
            // this.authService.logout()
            this.showOverlay = false
            this.router.navigate(['/auth/login'])
            // this.toastr.error(res.error, "");

          }
          else {
            this.toastr.error(res.error, "");
            this.showOverlay = false;

            // console.log('error mesg',res)
          }
          // console.error('this.order_status is undefined or empty');
        }
 // Process monthlyData as needed
      } else {
        this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      // if (error.status === 502) {
      // } else {
      // }
    });
    

  }
  createSalesAnalyticsChart(orderStatusData: { [key: string]: number }[]): void {

    const allKeys: string[] = orderStatusData.reduce((keys, entry) => {
      const entryKeys = Object.keys(entry);
      return keys.concat(entryKeys);
    }, []);


    const uniqueLabels = Array.from(new Set(allKeys));
    const total = uniqueLabels.reduce((sum, label) => sum + (orderStatusData.find(entry => entry[label])?.[label] || 0), 0);

    this.salesAnalytics = {
      series: uniqueLabels.map(label => orderStatusData.find(entry => entry[label])?.[label] || 0),

      chart: {
        height: 330,
        type: 'donut',
      },
      labels: uniqueLabels,
      plotOptions: {
        pie: {
          donut: {
            size: '75%'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false,
      },
      colors: [
        '#5664d2',
        '#1cbb8c',
        '#eeb902',
        '#ff5733',
        '#8e44ad',
        '#3498db',
        '#2ecc71',
        '#e74c3c',
        '#f39c12',
        '#d35400',
        '#c0392b',
        '#27ae60',
        '#f1c40f'
      ],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value: number) {
            return `${((value / total) * 100).toFixed(2)}%`;
          }
        }
      }
    };
  }
  basicColumChart: ChartType = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '45%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: ['#5664d2', '#1cbb8c', '#fcb92c'],
    series: [
      {
        name: 'Order Count',
        data: []
      },
      {
        name: 'Selling Price',
        data: [],
        yAxisIndex: 1, // Use the secondary y-axis for 'Selling Price'
      },
    ],
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        title: {
          text: 'Order Count'
        },
        labels: {
          formatter: function (value) {
            return Math.round(value); 
          }
        }
      },
      {
        opposite: true,
        title: {
          text: 'Selling Price (thousands)'
        },
        labels: {
          formatter: function (value) {
            return Math.round(value); 
          }
        }
      }
    ],
  
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val;
        }
      }
    }
  };

  // updateAndFetchData(interval: string) {
  //   this.updateChart(interval);
  //   this.fetch_data(interval);
  // }
  updateChart1(interval: "Yearly" | "Weekly" | "Monthly") {
    this.updateChart(interval);
    this.fetch_data(interval);
  }
  getKey(status: any): string {
    return Object.keys(status)[0] || '';
  }

  getValue(status: any): number {
    return Object.values(status)[0] as number;
  }
  fetchdata2() {
    this.superuser = localStorage.getItem('is_superuser')
    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
      // console.log(this.portal_name, this.portal_id, this.portal_account_id, 'jhgfdsaasdfghjkkjhgfds')


    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_account_id': this.portal_account_id,
    }
    this.authService.top_order(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.toporder = mes.body.top_order
        // console.log(this.toporder)

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          // this.toastr.error(res.error, "");
          this.router.navigate(['/auth/login'])
        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          //   this.isCancelled = true;
          // this.selectedOption = ''
          // this.searchTerm = '';
          //   // console.log('error mesg',res)
        }
        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      // if (error.status === 502) {
      // } else {
      // }
    });
    

  }
  toggleView(): void {
    this.showFullView = !this.showFullView;
    this.visibleCards = this.showFullView ? this.toporder.length : 3;
  }
  getProgressBarWidth(index: number) {
    const totalValues = this.order_status.length - 1;
    const progress = (index / totalValues) * 100;
    return progress + '%';
  }

  // account_details() {
  //   this.superuser = localStorage.getItem('is_superuser')

  //   if (this.superuser == 'true') {
  //     this.portal_name = localStorage.getItem('portal_name2')
  //     this.portal_id = localStorage.getItem('portal_id2')
  //     this.portal_account_id = localStorage.getItem('portal_account_id2')
  //     this.d2c_name = localStorage.getItem('d2c_name2')

  //   }
  //   else {
  //     this.portal_name = localStorage.getItem('portal_name')
  //     this.portal_id = localStorage.getItem('portal_id')
  //     this.portal_account_id = localStorage.getItem('portal_account_id')
  //     this.d2c_name = localStorage.getItem('d2c_name')
  //   }
  //   let data = {
  //     'portal_account_id': this.portal_account_id,
  //     'd2c_name': this.d2c_name
  //   }
  //   this.authService.account_details(data).subscribe((res: any) => {
  //     let mes = JSON.parse(res);
  //     if (mes.statusCode === 200) {
  //       this.showOverlay = false;
  //       this.account_details = mes

  //     } else {
  //       if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
  //         this.toastr.error(mes.error, "");
  //         this.showOverlay = false
  //         this.router.navigate(['/auth/login'])
  //       }
  //       else {
  //         this.toastr.error(res.error, "");
  //         this.showOverlay = false;
  //       }
       
  //     }
  //   }, (error) => {
  //     this.showOverlay = false;
     
  //   });
    

  // }
  selling_price() {
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_account_id': this.portal_account_id,
      'portal_id': this.portal_id
    }
    this.authService.selling_price(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.selling = mes.body.today
        this.selling1 = mes.body.yesterday
        // console.log(this.selling, 'selling')
        // console.log(this.selling1, 'selling1')
        const allDates = Array.from(new Set([...this.selling1.map(item => item.date), ...this.selling.map(item => item.date)]));

        allDates.sort();

        const seriesToday = this.selling.map(item => {
          return {
            x: new Date(`2000-01-01T${item.date}`).getTime(),
            y: item.orders,
            orders: item.orders

          };
        });

        const seriesYesterday = this.selling1.map(item => {
          return {
            x: new Date(`2000-01-01T${item.date}`).getTime(),
            y: item.orders,
            orders: item.orders

          };
        });


        this.splineAreaChart.series = [
          {
            name: 'Today',
            data: seriesToday

          },
          {
            name: 'Yesterday',
            data: seriesYesterday
          }
        ];
        this.splineAreaChart.xaxis.categories = allDates
      } else {
        this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    },(error) => {
      this.showOverlay = false;
      // if (error.status === 502) {
      // } else {
      // }
    });
    

  }
  splineAreaChart = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    series: [
      {
        name: 'Today',
        data: []
      },
      {
        name: 'Yesterday',
        data: []
      }
    ],
    colors: ['#8AFF33', '#FF5733'],
    xaxis: {
      type: 'time',
      categories: [],
      labels: {
        formatter: function (val) {
          // Format the time as per your requirement
          return new Date(val).toLocaleTimeString([], { hour: '2-digit' });
        }
      }
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    tooltip: {
      x: {
        format: 'HH',
        tickAmount: 10,

      },
    }
  };


  count() {
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_account_id': this.portal_account_id,
      'd2c_name': this.d2c_name
    }
    this.authService.count(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);
      // this.count1 = mes.shopify_cutomer_count
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.count = res
        this.platform_name = mes.d2c_name
        // console.log(this.count, 'countsdfghjkjhgdfghjkjhgfdsdfgh')
        this.count1 = mes.customer_count
        // console.log(this.count1,'this.count1')

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/auth/login'])

        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          // console.log('error mesg',res)
        }

        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

    

  }
  
  count22() {
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_account_id': this.portal_account_id,
      'd2c_name': this.d2c_name,
    }
    this.authService.repeated(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      console.log(mes,'repeated data');
      // this.count1 = mes.shopify_cutomer_count
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.repeated_count = mes.count

        

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/auth/login'])

        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          // console.log('error mesg',res)
        }

        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

    

  }
  count222() {
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data = {
      'portal_account_id': this.portal_account_id,
      'portal_id': this.portal_id,
      'page_number':1,
      'page_size':10,


    }
    this.authService.repeated1(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      console.log(mes,'repeated data of wooo');
      // this.count1 = mes.shopify_cutomer_count
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.repeated_count = mes.count
        

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/auth/login'])

        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          // console.log('error mesg',res)
        }

        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

    

  }

  searchTerm = '';

  placeholder_name = 'Search For Order Id'
  setplaceholder(data: any) {
    this.placeholder_name = data
    this.isOptionSelected = true;

  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue;
    if (this.selectedOption && this.searchTerm) {
      // this.combinedValue = `${this.selectedOption}:${this.searchTerm}`;
      this.ordersearch(this.searchTerm, this.selectedOption);
    }
  }
  // console.log(filterValue.value);
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // //  this. refreshIcon=true
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }

  onSearchChange() {
    // Trigger API call when the search term changes
  }
  ordersearch(value: any, key: any) {

    this.superuser = localStorage.getItem('is_superuser')

    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    // console.log(key);

    if (key === 'order_id') {
      const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
      this.data = {
        'order_id': cleanedOrderId,
        'portal_account_id': this.portal_account_id
      };
    } else {
      const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
      this.data = {
        'awb': cleanedOrderId,
        'portal_account_id': this.portal_account_id
      };
    }

    // console.log(this.data)

    this.authService.orders_search(this.data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.searchdata = mes.Data
        this.router.navigate(['/page/retailripple/details'])
        // console.log(this.searchdata, 'this.serachdata')
        this.isCancelled = false;




      }
      else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/auth/login'])
        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          this.isCancelled = true;
          this.selectedOption = ''
          this.searchTerm = '';
          // console.log('error mesg',res)
        }
        // this.showOverlay = false;
        // this.toastr.error(mes.error, 'Error')
        // this.isCancelled = true;
        // this.selectedOption = ''
        // this.searchTerm = '';



      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });
    

  }
  cancelSearch() {
    this.isCancelled = true;
    this.selectedOption = ''
    this.searchTerm = '';
    // You can add additional logic here based on your requirements
  }

  // handleDocumentClick(event: Event): void {
  //   if (!event.target.closest('.card11')) {
  //     this.cancelSearch();
  //   }
  // }

  // handleCardClick(event: Event): void {
  //   event.stopPropagation();
  // }
  handleDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.card11')) {
      this.cancelSearch();
    }
  }

  handleCardClick(event: Event): void {
    event.stopPropagation();
  }

//   lineColumAreaChart: ChartType = {
//     chart: {
//         height: 350,
//         type: 'line',
//         stacked: false,
//         toolbar: {
//             show: false
//         }
//     },
//     stroke: {
//         width: [0, 2, 4],
//         curve: 'smooth'
//     },
//     plotOptions: {
//         bar: {
//             columnWidth: '50%'
//         }
//     },
//     colors: ['#1cbb8c', '#fcb92c', '#5664d2'],
//     series: [{
//         name: 'Team A',
//         type: 'column',
//         data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
//     }, {
//         name: 'Team B',
//         type: 'area',
//         data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
//     }, {
//         name: 'Team C',
//         type: 'line',
//         data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
//     }],
//     fill: {
//         opacity: [0.85, 0.25, 1],
//         gradient: {
//             inverseColors: false,
//             shade: 'light',
//             type: 'vertical',
//             opacityFrom: 0.85,
//             opacityTo: 0.55,
//             stops: [0, 100, 100, 100]
//         }
//     },
//     // tslint:disable-next-line: max-line-length
//     labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'],
//     markers: {
//         size: 0
//     },
//     legend: {
//         offsetY: -10,
//     },
//     xaxis: {
//         type: 'datetime',
//     },
//     yaxis: {
//         title: {
//             text: 'Points',
//         },
//     },
//     tooltip: {
//         shared: true,
//         intersect: false,
//         y: {
//             formatter(y) {
//                 if (typeof y !== 'undefined') {
//                     return y.toFixed(0) + ' points';
//                 }
//                 return y;
//             }
//         }
//     },
//     grid: {
//         borderColor: '#f1f1f1'
//     }
// };
target_view(){
  this.showOverlay = true;
  const formattedDate = this.data1!.value?.format('MM-YYYY');
  let data = {
    'month':formattedDate
  }

  this.authService.target_view(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      const counttt = mes.data
      this.target11 = mes.target
      this.achived = Math.round(mes.achived);
      console.log(this.count,'count of target view')
      const categories = counttt.map(obj => Object.keys(obj)[0]);
      this.lineColumAreaChart.xaxis.categories = categories;

      const years = counttt.map(item => Object.keys(item)[0]);
const orderCounts = counttt.map(item => item[Object.keys(item)[0]].order_count);
const totalSellingPrices = counttt.map(item => item[Object.keys(item)[0]].total_selling_price);
this.lineColumAreaChart.series = [
  {
    name: 'Order Count',
    type: 'column',
    data: years.map((count, index) => ({
      x: categories[index],
      y: orderCounts[index]
    })),
  },
  {
    name: 'Total Selling Price',
    type:'area',
    data: totalSellingPrices.map((price, index) => ({
      x: categories[index],
      y: price
    })),
  },
];


    } else {
      if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
        this.showOverlay = false
        this.router.navigate(['/auth/login'])
      }
      else {
        this.toastr.error(mes.error, "");
        this.showOverlay = false;
      }

     
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
    } else {
    }
  });

}
lineColumAreaChart: ChartType = {
  chart: {
    height: 350,
    type: 'line',
    stacked: false,
    toolbar: {
      show: false
    }
  },
  stroke: {
    width: [0, 2, 4],
    curve: 'smooth'
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  colors: ['#1cbb8c', '#fcb92c', '#5664d2'],
  series: [],
  fill: {
    opacity: [0.85, 0.25, 1],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100]
    }
  },
  labels: [],
  markers: {
    size: 0
  },
  legend: {
    offsetY: -10,
  },
  xaxis: {
    categories: [],
  },
  yaxis: [
    {
      title: {
        text: 'Order Count'
      },
      labels: {
        formatter: function (value) {
          return Math.round(value); 
        }
      }
    },
    {
      opposite: true,
      title: {
        text: 'Total Selling Price'
      },
      labels: {
        formatter: function (value) {
          return Math.round(value); 
        }
      }
    }
  ],

  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter(y) {
        if (typeof y !== 'undefined') {
          return y.toFixed(0);
        }
        return y;
      }
    }
  },
  grid: {
    borderColor: '#f1f1f1'
  }
};
data1 = new FormControl(moment());
data2 = new FormControl(moment());
data3 = new FormControl(moment());




chosenYearHandler(normalizedYear: Moment) {
  const ctrlValue = this.data1.value;
  ctrlValue!.year(normalizedYear.year());
  this.data1.setValue(ctrlValue);
}
chosenYearHandler1(normalizedYear: Moment) {
  const ctrlValue = this.data2.value;
  ctrlValue!.year(normalizedYear.year());
  this.data2.setValue(ctrlValue);
}

chosenYearHandler2(normalizedYear: Moment) {
  const ctrlValue = this.data3.value;
  ctrlValue!.year(normalizedYear.year());
  this.data3.setValue(ctrlValue);
}

chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  if (normalizedMonth && this.data1) {
    const ctrlValue = this.data1.value;
    ctrlValue!.month(normalizedMonth.month());
    this.data1.setValue(ctrlValue);
    console.log('this.data1', this.data1);
    datepicker.close();
  } else {
    console.error('normalizedMonth or this.date1 is undefined');
  }
}
chosenMonthHandler1(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  if (normalizedMonth && this.data2) {
    const ctrlValue = this.data2.value;
    ctrlValue!.month(normalizedMonth.month());
    this.data2.setValue(ctrlValue);
    console.log('this.data1', this.data2);
    datepicker.close();
  } else {
    console.error('normalizedMonth or this.date1 is undefined');
  }
}
chosenMonthHandler2(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  if (normalizedMonth && this.data3) {
    const ctrlValue = this.data3.value;
    ctrlValue!.month(normalizedMonth.month());
    this.data3.setValue(ctrlValue);
    console.log('this.data1', this.data3);
    datepicker.close();
  } else {
    console.error('normalizedMonth or this.date1 is undefined');
  }
}

admin(){
  this.basicColumChart = []
  this.showOverlay = true
  this.admin1 = true
  this.bulk_status_details('7')
  this.bulk_status_details2('7')
}
admin2(){
  // this.basicColumChart = []
  this.admin1 = false
  this.showOverlay = true
  this.fetch_data(this.selectedInterval);
  this.fetchdata1(this.day);
    this.basicColumChart = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          columnWidth: '45%',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      colors: ['#5664d2', '#1cbb8c', '#fcb92c'],
      series: [
        {
          name: 'Order Count',
          data: []
        },
        {
          name: 'Selling Price',
          data: [],
          yAxisIndex: 1, // Use the secondary y-axis for 'Selling Price'
        },
      ],
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          title: {
            text: 'Order Count'
          },
          labels: {
            formatter: function (value) {
              return Math.round(value); 
            }
          }
        },
        {
          opposite: true,
          title: {
            text: 'Selling Price (thousands)'
          },
          labels: {
            formatter: function (value) {
              return Math.round(value); 
            }
          }
        }
      ],
    
      fill: {
        opacity: 1
      },
      grid: {
        borderColor: '#f1f1f1'
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return val;
          }
        }
      }
    };

  }

status_details:any
bulk_status_details(data1: any) {
  this.showOverlay = true;
  let data = {
    'days': data1
  };
  this.authService.bulk_status_details(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.status_details = mes.status_details;

      const categories = [];
      const seriesData = [];

      // Initialize empty series for each status
      const statusSeries = {
        "Payment Pending": [],
        "Payment Success": [],
        "Created": [],
        "Packed": [],
        "Dispatched": [],
        "In Transit": [],
        "Delivered": [],
        "Cancelled": [],
        "Return Requested": [],
        "Customer Return": [],
        "Cr-Received": [],
        "Rto-In Transit": [],
        "Rto": [],
        "Shipment_Issue": []
      };

      this.status_details.forEach((detail) => {
        const key = Object.keys(detail)[0];
        const values = detail[key];

        categories.push(key);

        // Populate the series data with the corresponding values
        for (let status in statusSeries) {
          const value = values[status] || 0;
          if (value > 0) { // Only consider non-zero values
            statusSeries[status].push(value);
          } else {
            statusSeries[status].push(null); // Skip zero values
          }
        }
      });

      // Convert series data into the required format
      for (let status in statusSeries) {
        const data = statusSeries[status];
        if (data.some(value => value !== null)) { // Include only non-empty series
          seriesData.push({
            name: status,
            data: data,
            color: this.getStatusColor(status) // Use a function to get the color based on the status
          });
        }
      }

      this.basicColumChart = {
        series: seriesData,
        chart: {
          type: 'bar',
          height: 350,
          stacked: true, // Enable stacking of bars
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#fff'] // Stroke color for contrast
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              padding: 10 // Adds padding to x-axis labels for better readability
            }
          }
        },
        yaxis: {
          title: {
            text: 'Total Count'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const category = w.config.xaxis.categories[dataPointIndex];
            let total = 0;

            // Calculate total value for the current data point across all series
            series.forEach((data) => {
              const value = data[dataPointIndex];
              if (value !== null) {
                total += value;
              }
            });

            let tooltipContent = `<div>`;

            // Display the category and total with a light grey background
            tooltipContent += `<div style="display: flex; justify-content: space-between; font-weight: bold;padding:10px;
                                margin-bottom: 10px; background-color: #dce4e3; padding: 5px;">
                                 <div>${category}</div>
                                 <div>Total: ${total}</div>
                               </div>`;

            // Iterate through each series to show the status and its value
            series.forEach((data, index) => {
              const status = w.config.series[index].name;
              const value = data[dataPointIndex];
              const color = w.config.colors[index];
              tooltipContent += `
                <div style="display: flex; align-items: center; margin-bottom: 5px; padding-left:10px; padding-right:10px;padding-top:2px;padding-bottom:2px;">
                  <div style="width: 10px; height: 10px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></div>
                  <div>${status}: ${value}</div>
                </div>`;
            });

            tooltipContent += `</div>`;
            return tooltipContent;
          }
        },
        colors: seriesData.map(data => data.color) // Apply the colors based on the series
      };
    } else {
      this.showOverlay = false;
    }
  }, (error) => {
    this.showOverlay = false;
  });
}



private getStatusColor(status: string): string {
  const colorMap = {
    "Payment Pending": '#FF5733',  // Bright Orange
    "Payment Success": '#3498DB',  // Vivid Blue
    "Created": '#8E44AD',          // Deep Purple
    "Packed": '#1ABC9C',           // Aqua Green
    "Dispatched": '#F39C12',       // Bright Gold/Yellow
    "In Transit": '#2980B9',       // Tomato Red
    "Delivered": '#2ECC71',        // Bright Green
    "Cancelled": '#FF6347',        // Strong Blue
    "Return Requested": '#9B59B6', // Vivid Violet
    "Customer Return": '#D35400',  // Dark Orange
    "Cr-Received": '#27AE60',      // Emerald Green
    "Rto-In Transit": '#16A085',   // Dark Teal
    "Rto": '#34495E',              // Dark Slate Blue
    "Shipment_Issue": '#AF7AC5'    // Light Purple
  };
  return colorMap[status] || '#546E7A'; // Default to dark gray if status is not found
}
formData99:boolean = false

onSelectChange(event: any) {
  const selectedValue = event.target.value;
  console.log(selectedValue,'selectedValue')
  if(selectedValue === 'custom'){
    this.formData99 = true
  }
  else if (selectedValue === 'today'){
    this.bulk_status_details('0')
  }
  else if (selectedValue === 'yesterday'){
    this.bulk_status_details('1')
  }
  else if (selectedValue === 'last_7_days'){
    this.bulk_status_details('7')
  }
  else if (selectedValue === 'last_15_days'){
    this.bulk_status_details('15')
  }

}
data98:any

bulk_status_details1() {
  this.showOverlay = true
  // const formattedDate = this.data2!.value?.format('MM-YYYY');
  // console.log(formattedDate,' formattedDate')
  // let data = {
  //   'start_date': 
  //   'end_date':
  // };
 const formattedDate = this.data2!.value?.format('MM-YYYY'); // Ensure this is returning 'MM-YYYY'
console.log('Formatted Date:', formattedDate);

const [month, year] = formattedDate.split('-').map(Number);

if (!isNaN(month) && !isNaN(year)) {
  // Create Date objects for the start and end of the month
  const startDate = new Date(year, month - 1, 1); // Start of the month
  const endDate = new Date(year, month, 0); // Last day of the month

  // Format dates to 'YYYY-MM-DD'
  const startFormatted = startDate.toISOString().split('T')[0];
  const endFormatted = endDate.toISOString().split('T')[0];

  this.data98 = {
    start_date: startFormatted,
    end_date: endFormatted
  };

  console.log('Start Date:', startFormatted);
  console.log('End Date:', endFormatted);
  console.log('Data:', this.data98);
} else {
  console.error('Invalid month or year in formattedDate:', formattedDate);
}

  

this.authService.bulk_status_details1(this.data98).subscribe((res: any) => {
  let mes = JSON.parse(res);
  if (mes.statusCode === 200) {
    this.showOverlay = false;
    this.status_details = mes.status_details;

    const categories = [];
    const seriesData = [];

    // Initialize empty series for each status
    const statusSeries = {
      "Payment Pending": [],
      "Payment Success": [],
      "Created": [],
      "Packed": [],
      "Dispatched": [],
      "In Transit": [],
      "Delivered": [],
      "Cancelled": [],
      "Return Requested": [],
      "Customer Return": [],
      "Cr-Received": [],
      "Rto-In Transit": [],
      "Rto": [],
      "Shipment_Issue": []
    };

    this.status_details.forEach((detail) => {
      const key = Object.keys(detail)[0];
      const values = detail[key];

      categories.push(key);

      // Populate the series data with the corresponding values
      for (let status in statusSeries) {
        const value = values[status] || 0;
        if (value > 0) { // Only consider non-zero values
          statusSeries[status].push(value);
        } else {
          statusSeries[status].push(null); // Skip zero values
        }
      }
    });

    // Convert series data into the required format
    for (let status in statusSeries) {
      const data = statusSeries[status];
      if (data.some(value => value !== null)) { // Include only non-empty series
        seriesData.push({
          name: status,
          data: data,
          color: this.getStatusColor(status) // Use a function to get the color based on the status
        });
      }
    }

    this.basicColumChart = {
      series: seriesData,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true, // Enable stacking of bars
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['#fff'] // Stroke color for contrast
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            padding: 10 // Adds padding to x-axis labels for better readability
          }
        }
      },
      yaxis: {
        title: {
          text: 'Total Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const category = w.config.xaxis.categories[dataPointIndex];
          let total = 0;

          // Calculate total value for the current data point across all series
          series.forEach((data) => {
            const value = data[dataPointIndex];
            if (value !== null) {
              total += value;
            }
          });

          let tooltipContent = `<div>`;

          // Display the category and total with a light grey background
          tooltipContent += `<div style="display: flex; justify-content: space-between; font-weight: bold;padding:10px;
                              margin-bottom: 10px; background-color: #dce4e3; padding: 5px;">
                               <div>${category}</div>
                               <div>Total: ${total}</div>
                             </div>`;

          // Iterate through each series to show the status and its value
          series.forEach((data, index) => {
            const status = w.config.series[index].name;
            const value = data[dataPointIndex];
            const color = w.config.colors[index];
            tooltipContent += `
              <div style="display: flex; align-items: center; margin-bottom: 5px; padding-left:10px; padding-right:10px;padding-top:2px;padding-bottom:2px;">
                <div style="width: 10px; height: 10px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></div>
                <div>${status}: ${value}</div>
              </div>`;
          });

          tooltipContent += `</div>`;
          return tooltipContent;
        }
      },
      colors: seriesData.map(data => data.color) // Apply the colors based on the series
    };
  } else {
    this.showOverlay = false;
  }
}, (error) => {
  this.showOverlay = false;
});
}

portal_wise_details:any


bulk_status_details2(data1:any) {
  this.showOverlay = true
  let data = {
    'days': data1,
    // 'start_date':'2024-08-01',
    // 'end_date':'2024-08-25'
  };
  this.authService.portalwise_status_details1(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.portal_wise_details = mes.status_details
      this.portal_wise_details = mes.status_details;
      console.log(this.portal_wise_details, 'this.portal_wise_details');

      const uniqueLabels = Object.keys(this.portal_wise_details);
      const seriesData: number[] = Object.values(this.portal_wise_details);

      // Calculate the total for percentage calculation in the tooltip
      const total: number = seriesData.reduce((acc, value) => acc + value, 0);

      this.salesAnalytics = {
        series: seriesData,
        chart: {
          height: 330,
          type: 'donut',
        },
        labels: uniqueLabels,
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        colors: [
          '#5664d2',
          '#1cbb8c',
          '#eeb902',
          '#ff5733',
          '#8e44ad',
          '#3498db',
          '#2ecc71',
          '#e74c3c',
          '#f39c12',
          '#d35400',
          '#c0392b',
          '#27ae60',
          '#f1c40f'
        ],
        tooltip: {
          enabled: true,
          y: {
            formatter: (value: number) => {
              return `${((value / total) * 100).toFixed(2)}%`;
            }
          }
        }
      };
    
    } else {
      this.showOverlay = false;
    }
  }, (error) => {
    this.showOverlay = false;
  });
}

formData00:boolean = false

onSelectChange1(event: any) {
  const selectedValue = event.target.value;
  console.log(selectedValue,'selectedValue')
  if(selectedValue === 'custom'){
    this.formData00 = true
  }
  else if (selectedValue === 'today'){
    this.bulk_status_details2('0')
  }
  else if (selectedValue === 'yesterday'){
    this.bulk_status_details2('1')
  }
  else if (selectedValue === 'last_7_days'){
    this.bulk_status_details2('7')
  }
  else if (selectedValue === 'last_15_days'){
    this.bulk_status_details2('15')
  }

}
data988:any


bulk_status_details3() {
  this.showOverlay = true

  const formattedDate = this.data3!.value?.format('MM-YYYY'); // Ensure this is returning 'MM-YYYY'
console.log('Formatted Date:', formattedDate);

const [month, year] = formattedDate.split('-').map(Number);

if (!isNaN(month) && !isNaN(year)) {
  // Create Date objects for the start and end of the month
  const startDate = new Date(year, month - 1, 1); // Start of the month
  const endDate = new Date(year, month, 0); // Last day of the month

  // Format dates to 'YYYY-MM-DD'
  const startFormatted = startDate.toISOString().split('T')[0];
  const endFormatted = endDate.toISOString().split('T')[0];

  this.data988 = {
    start_date: startFormatted,
    end_date: endFormatted
  };

  console.log('Start Date:', startFormatted);
  console.log('End Date:', endFormatted);
  console.log('Data:', this.data98);
} else {
  console.error('Invalid month or year in formattedDate:', formattedDate);
}

  this.authService.portalwise_status_details(this.data988).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.portal_wise_details = mes.status_details
      this.portal_wise_details = mes.status_details;
      console.log(this.portal_wise_details, 'this.portal_wise_details');

      const uniqueLabels = Object.keys(this.portal_wise_details);
      const seriesData: number[] = Object.values(this.portal_wise_details);

      // Calculate the total for percentage calculation in the tooltip
      const total: number = seriesData.reduce((acc, value) => acc + value, 0);

      this.salesAnalytics = {
        series: seriesData,
        chart: {
          height: 330,
          type: 'donut',
        },
        labels: uniqueLabels,
        plotOptions: {
          pie: {
            donut: {
              size: '75%'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        colors: [
          '#5664d2',
          '#1cbb8c',
          '#eeb902',
          '#ff5733',
          '#8e44ad',
          '#3498db',
          '#2ecc71',
          '#e74c3c',
          '#f39c12',
          '#d35400',
          '#c0392b',
          '#27ae60',
          '#f1c40f'
        ],
        tooltip: {
          enabled: true,
          y: {
            formatter: (value: number) => {
              return `${((value / total) * 100).toFixed(2)}%`;
            }
          }
        }
      };
    
    } else {
      this.showOverlay = false;
    }
  }, (error) => {
    this.showOverlay = false;
  });
}

route11(){
  this.router.navigate(['/page/retailripple/dispute-view'])

}

route111(){
  this.toastr.success("Response will be sent to your Email Id.");
  this.authService.mail_sharing().subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
      
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      // this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}



}












